<template>
<div v-loading="loading" class="column">
  <div id="download" class="column">
    <div class="row mt50">
      <div v-if="monthList.length!=0" class="column_base">
        <div class="title">班级综合报告</div>
        <div
          v-if="monthType == monthList[0].value"
          class="month_select1 row center mt25"
        >
          <div class="circle1"></div>
          <div class="top_text">{{ monthList[0].name }}</div>
        </div>
        <div
          v-else
          class="month_normal1 row center mt25"
          @click="clickMonth(monthList[0])"
        >
          <div class="circle1 small_circle"></div>
          <div class="top_text">{{ monthList[0].name }}</div>
        </div>
        <div
          v-if="monthType == monthList[1].value"
          class="month_select2 row center"
        >
          <div class="circle2"></div>
          <div class="top_text">{{ monthList[1].name }}</div>
        </div>
        <div
          v-else
          class="month_normal1 row center"
          @click="clickMonth(monthList[1])"
        >
          <div class="circle2 small_circle"></div>
          <div class="top_text">{{ monthList[1].name }}</div>
        </div>
        <div
          v-if="monthType == monthList[2].value"
          class="month_select3 row center"
        >
          <div class="circle3"></div>
          <div class="top_text">{{ monthList[2].name }}</div>
        </div>
        <div
          v-else
          class="month_normal1 row center"
          @click="clickMonth(monthList[2])"
        >
          <div class="circle3 small_circle"></div>
          <div class="top_text">{{ monthList[2].name }}</div>
        </div>
        <div
          v-if="monthType == monthList[3].value"
          class="month_select4 row center"
        >
          <div class="circle4"></div>
          <div class="top_text">{{ monthList[3].name }}</div>
        </div>
        <div
          v-else
          class="month_normal1 row center"
          @click="clickMonth(monthList[3])"
        >
          <div class="circle4 small_circle"></div>
          <div class="top_text">{{ monthList[3].name }}</div>
        </div>
        <div
          v-if="monthType == monthList[4].value"
          class="month_select5 row center"
        >
          <div class="circle5"></div>
          <div class="top_text">{{ monthList[4].name }}</div>
        </div>
        <div
          v-else
          class="month_normal1 row center"
          @click="clickMonth(monthList[4])"
        >
          <div class="circle5 small_circle"></div>
          <div class="top_text">{{ monthList[4].name }}</div>
        </div>
        <div
          v-if="monthType == monthList[5].value"
          class="month_select6 row center"
        >
          <div class="circle6"></div>
          <div class="top_text">{{ monthList[5].name }}</div>
        </div>
        <div
          v-else
          class="month_normal1 row center"
          @click="clickMonth(monthList[5])"
        >
          <div class="circle6 small_circle"></div>
          <div class="top_text">{{ monthList[5].name }}</div>
        </div>
      </div>
      <div class="image_c">
        <div class="title1">班级总人数</div>
        <div class="image_l">
          <img
            v-if="monthType == monthList[0].value"
            class="image"
            src="../../assets/img/home/home/PES/Pic_TB_A.png"
          />
          <div v-if="monthType == monthList[0].value" class="num1">
            {{ num1 }}
          </div>
          <div v-if="monthType == monthList[0].value" class="num2">
            {{ num2 }}
          </div>
          <div v-if="monthType == monthList[0].value" class="num3">
            {{ num3 }}
          </div>
          <img
            v-if="monthType == monthList[1].value"
            class="image"
            src="../../assets/img/home/home/PES/Pic_TB_B.png"
          />
          <div v-if="monthType == monthList[1].value" class="num4">
            {{ num1 }}
          </div>
          <div v-if="monthType == monthList[1].value" class="num5">
            {{ num2 }}
          </div>
          <div v-if="monthType == monthList[1].value" class="num6">
            {{ num3 }}
          </div>
          <img
            v-if="monthType == monthList[2].value"
            class="image"
            src="../../assets/img/home/home/PES/Pic_TB_C.png"
          />
          <div v-if="monthType == monthList[2].value" class="num7">
            {{ num1 }}
          </div>
          <div v-if="monthType == monthList[2].value" class="num8">
            {{ num2 }}
          </div>
          <div v-if="monthType == monthList[2].value" class="num9">
            {{ num3 }}
          </div>
          <img
            v-if="monthType == monthList[3].value"
            class="image"
            src="../../assets/img/home/home/PES/Pic_TB_D.png"
          />
          <div v-if="monthType == monthList[3].value" class="num10">
            {{ num1 }}
          </div>
          <div v-if="monthType == monthList[3].value" class="num11">
            {{ num2 }}
          </div>
          <div v-if="monthType == monthList[3].value" class="num12">
            {{ num3 }}
          </div>
          <img
            v-if="monthType == monthList[4].value"
            class="image"
            src="../../assets/img/home/home/PES/Pic_TB_E.png"
          />
          <div v-if="monthType == monthList[4].value" class="num13">
            {{ num1 }}
          </div>
          <div v-if="monthType == monthList[4].value" class="num14">
            {{ num2 }}
          </div>
          <div v-if="monthType == monthList[4].value" class="num15">
            {{ num3 }}
          </div>
          <img
            v-if="monthType == monthList[5].value"
            class="image"
            src="../../assets/img/home/home/PES/Pic_TB_F.png"
          />
          <div v-if="monthType == monthList[5].value" class="num16">
            {{ num1 }}
          </div>
          <div v-if="monthType == monthList[5].value" class="num17">
            {{ num2 }}
          </div>
          <div v-if="monthType == monthList[5].value" class="num18">
            {{ num3 }}
          </div>
        </div>
        <div class="row_center" style="width: 100%">
          <div class="title1 flex">测试总人数</div>
          <div class="title1">测试合格人数</div>
        </div>
      </div>
      <el-select
        v-if="flag != 1"
        class="form-control"
        style="display: flex; justify-content: center; align-items: center"
        v-model="currentGradeId"
        @change="change"
        placeholder="请选择"
      >
        <el-option
          v-for="val in classList"
          :key="val.id"
          :label="val.name"
          :value="val.id"
        >
        </el-option>
      </el-select>
    </div>
    <div class="white_bg mt125">
      <div class="title">班级综合报告</div>
      <div v-if="operateType == 1" class="tz_select">体质</div>
      <div v-else class="tz_normal" @click="clickOperateType(1)">体质</div>
      <div v-if="operateType == 2" class="yd_select">运动</div>
      <div v-else class="yd_normal" @click="clickOperateType(2)">运动</div>
      <div class="downLoad" @click="downLoad">下载</div>
    </div>
    <div class="width1242 mt36">
        <div class="row">
          <img class="pes" src="../../assets/img/home/home/PES/pes.png" />
          <div class="column_base mrl22">
            <div class="school_name">{{ tzData.schoolName }}</div>
            <div class="row mt9">
              <div class="all_num">幼儿人数：</div>
              <div class="school_name">{{ tzData.studentCount }}</div>
            </div>
            <div class="row_center mt9">
              <img
                class="address"
                src="../../assets/img/home/home/PES/Icon_BG_Address.png"
              />
              <div class="site">{{ tzData.address }}</div>
            </div>
          </div>
          <div class="flex"></div>
          <div class="column_end">
            <div class="gjc">{{ monthValue }}运动关键词</div>
            <img
              v-if="tzData.kewWord && tzData.kewWord.indexOf('保持') != -1"
              class="gjc_image"
              src="../../assets/img/home/home/PES/Pic_BC.png"
            />
            <img
              v-else
              class="gjc_image1"
              src="../../assets/img/home/home/PES/Icon_add_run.png"
            />
          </div>
        </div>
        <div class="top_lan">
          <div class="title1 flex">指标评价</div>
          <div class="title2">数据分析</div>
          <div class="width263 row">
            <div class="title3">数据指导</div>
          </div>
        </div>
        <div class="line"></div>
        <div v-if="operateType == 1" class="width1242">
          <div v-if="tzData.height != null" class="width1242">
            <div class="row">
              <img
                class="image1"
                src="../../assets/img/home/home/PES/Pic_SG.png"
              />
              <div class="column_base flex">
                <div class="row mt12 width586">
                  <div class="flag_text">身高</div>
                  <div class="flex"></div>
                  <div v-if="tzData.height" class="avg_text">
                    平均身高：{{ tzData.height.avgValue }}cm
                  </div>
                </div>
                <div class="row">
                  <div class="column_base">
                    <div class="avg_pf">园所平均身高评分</div>
                    <div
                      class="progressColor mt4"
                      :style="{
                        width: width1 + 'rem',
                        'border-color':
                          score1 < 60
                            ? '#2D8A00'
                            : score1 < 70
                            ? '#AD8600'
                            : score1 < 80
                            ? '#009C80'
                            : '#AD8600',
                        background:
                          score1 < 60
                            ? '#47D800'
                            : score1 < 70
                            ? '#F0BA00'
                            : score1 < 80
                            ? '#00E2BA'
                            : '#FE0101',
                      }"
                    ></div>
                  </div>
                  <div class="fs">{{ score1 }}分</div>
                </div>
                <img
                  v-if="score1 <= 60"
                  class="progress_arrow"
                  :style="{ 'margin-left': width1 - 0.05 + 'rem' }"
                  src="../../assets/img/home/home/PES/Icon_Arrow_G.png"
                />
                <img
                  v-else-if="score1 <= 80"
                  class="progress_arrow"
                  :style="{ 'margin-left': width1 - 0.05 + 'rem' }"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Y.png"
                />
                <img
                  v-else-if="score1 <= 90"
                  class="progress_arrow"
                  :style="{ 'margin-left': width1 - 0.05 + 'rem' }"
                  src="../../assets/img/home/home/PES/Icon_Arrow_B.png"
                />
                <img
                  v-else
                  class="progress_arrow"
                  :style="{ 'margin-left': width1 - 0.05 + 'rem' }"
                  src="../../assets/img/home/home/PES/Icon_Arrow_R.png"
                />
                <img
                  class="progress_line"
                  src="../../assets/img/home/home/PES/Pic_JDT_PJ.png"
                />
              </div>
              <div class="line_v mt17"></div>
              <div class="width213 column_base mt13">
                <div class="row center mrl5 max">
                  <div class="text1 flex">最大值：</div>
                  <div class="text1">{{ tzData.height.best.value }}cm</div>
                  <img
                    v-if="tzData.height.best.isDown"
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                  />
                  <img
                    v-else
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                  />
                </div>
                <div class="row center mrl5 max mt20">
                  <div class="text1 flex">最小值：</div>
                  <div class="text1">{{ tzData.height.worst.value }}cm</div>
                  <img
                    v-if="tzData.height.worst.isDown"
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                  />
                  <img
                    v-else
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                  />
                </div>
                <div class="row center mrl5 max mt20">
                  <div class="text1 flex">本期完成率：</div>
                  <div class="text1">
                    {{ tzData.height.completeRate.value }}%
                  </div>
                  <img
                    v-if="tzData.height.completeRate.isDown"
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                  />
                  <img
                    v-else
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                  />
                </div>
                <div class="row center mrl5 max mt20">
                  <div class="text1 flex">本期增长率：</div>
                  <div class="text1">{{ tzData.height.riseRate.value }}%</div>
                  <img
                    v-if="tzData.height.riseRate.isDown"
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                  />
                  <img
                    v-else
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                  />
                </div>
              </div>
              <div class="line_v mt17"></div>
              <div class="width263 row mt13">
                <div class="column_center mrl42">
                  <div class="ys_pos">
                    <div
                      class="pro"
                      :style="{ height: ysHeight1 + 'rem' }"
                    ></div>
                    <div class="bg flex"></div>
                  </div>
                  <div class="ys_text">园所</div>
                </div>
                <div class="column_center mrl13">
                  <div class="ys_pos">
                    <div
                      class="pro"
                      :style="{ height: qgHeight1 + 'rem' }"
                    ></div>
                    <div class="qg_bg flex"></div>
                  </div>
                  <div class="qg_text">全国</div>
                </div>
                <div class="column_end flex">
                  <div v-if="tzData.height.r1.isDown" class="status1">
                    {{ tzData.height.r1.remark }}
                  </div>
                  <div v-else class="status2">
                    {{ tzData.height.r1.remark }}
                  </div>
                  <div v-if="tzData.height.r2.isDown" class="status1 mt19">
                    {{ tzData.height.r2.remark }}
                  </div>
                  <div v-else class="status2 mt19">
                    {{ tzData.height.r2.remark }}
                  </div>
                  <div v-if="tzData.height.r3.isDown" class="status1 mt19">
                    {{ tzData.height.r3.remark }}
                  </div>
                  <div v-else class="status2 mt19">
                    {{ tzData.height.r3.remark }}
                  </div>
                  <div v-if="tzData.height.r4.isDown" class="status1 mt19">
                    {{ tzData.height.r4.remark }}
                  </div>
                  <div v-else class="status2 mt19">
                    {{ tzData.height.r4.remark }}
                  </div>
                </div>
              </div>
            </div>
            <div class="line mt4"></div>
            <div class="row">
              <img
                class="image1"
                src="../../assets/img/home/home/PES/Pic_TZ.png"
              />
              <div class="column_base flex">
                <div class="row mt12 width586">
                  <div class="flag_text">体重</div>
                  <div class="flex"></div>
                  <div v-if="tzData.weight" class="avg_text">
                    平均体重：{{ tzData.weight.avgValue }}Kg
                  </div>
                </div>
                <div class="row">
                  <div class="column_base">
                    <div class="avg_pf">园所平均体重评分</div>
                    <div
                      class="progressColor mt4"
                      :style="{
                        width: width2 + 'rem',
                        'border-color':
                          score2 < 60
                            ? '#2D8A00'
                            : score2 < 70
                            ? '#AD8600'
                            : score2 < 80
                            ? '#009C80'
                            : '#AD8600',
                        background:
                          score2 < 60
                            ? '#47D800'
                            : score2 < 70
                            ? '#F0BA00'
                            : score2 < 80
                            ? '#00E2BA'
                            : '#FE0101',
                      }"
                    ></div>
                  </div>
                  <div class="fs">{{ score2 }}分</div>
                </div>
                <img
                  v-if="score2 <= 60"
                  class="progress_arrow"
                  :style="{ 'margin-left': width2 - 0.05 + 'rem' }"
                  src="../../assets/img/home/home/PES/Icon_Arrow_G.png"
                />
                <img
                  v-else-if="score2 <= 80"
                  class="progress_arrow"
                  :style="{ 'margin-left': width2 - 0.05 + 'rem' }"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Y.png"
                />
                <img
                  v-else-if="score2 <= 90"
                  class="progress_arrow"
                  :style="{ 'margin-left': width2 - 0.05 + 'rem' }"
                  src="../../assets/img/home/home/PES/Icon_Arrow_B.png"
                />
                <img
                  v-else
                  class="progress_arrow"
                  :style="{ 'margin-left': width2 - 0.05 + 'rem' }"
                  src="../../assets/img/home/home/PES/Icon_Arrow_R.png"
                />
                <img
                  class="progress_line"
                  src="../../assets/img/home/home/PES/Pic_JDT_PJ.png"
                />
              </div>
              <div class="line_v mt17"></div>
              <div class="width213 column_base mt13">
                <div class="row center mrl5 max">
                  <div class="text1 flex">最大值：</div>
                  <div class="text1">{{ tzData.weight.best.value }}Kg</div>
                  <img
                    v-if="tzData.weight.best.isDown"
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                  />
                  <img
                    v-else
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                  />
                </div>
                <div class="row center mrl5 max mt20">
                  <div class="text1 flex">最小值：</div>
                  <div class="text1">{{ tzData.weight.worst.value }}Kg</div>
                  <img
                    v-if="tzData.weight.worst.isDown"
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                  />
                  <img
                    v-else
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                  />
                </div>
                <div class="row center mrl5 max mt20">
                  <div class="text1 flex">本期完成率：</div>
                  <div class="text1">
                    {{ tzData.weight.completeRate.value }}%
                  </div>
                  <img
                    v-if="tzData.weight.completeRate.isDown"
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                  />
                  <img
                    v-else
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                  />
                </div>
                <div class="row center mrl5 max mt20">
                  <div class="text1 flex">本期增长率：</div>
                  <div class="text1">{{ tzData.weight.riseRate.value }}%</div>
                  <img
                    v-if="tzData.weight.riseRate.isDown"
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                  />
                  <img
                    v-else
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                  />
                </div>
              </div>
              <div class="line_v mt17"></div>
              <div class="width263 row mt13">
                <div class="column_center mrl42">
                  <div class="ys_pos">
                    <div
                      class="pro"
                      :style="{ height: ysHeight2 + 'rem' }"
                    ></div>
                    <div class="bg flex"></div>
                  </div>
                  <div class="ys_text">园所</div>
                </div>
                <div class="column_center mrl13">
                  <div class="ys_pos">
                    <div
                      class="pro"
                      :style="{ height: qgHeight2 + 'rem' }"
                    ></div>
                    <div class="qg_bg flex"></div>
                  </div>
                  <div class="qg_text">全国</div>
                </div>
                <div class="column_end flex">
                  <div v-if="tzData.weight.r1.isDown" class="status1">
                    {{ tzData.weight.r1.remark }}
                  </div>
                  <div v-else class="status2">
                    {{ tzData.weight.r1.remark }}
                  </div>
                  <div v-if="tzData.weight.r2.isDown" class="status1 mt19">
                    {{ tzData.weight.r2.remark }}
                  </div>
                  <div v-else class="status2 mt19">
                    {{ tzData.weight.r2.remark }}
                  </div>
                  <div v-if="tzData.weight.r3.isDown" class="status1 mt19">
                    {{ tzData.weight.r3.remark }}
                  </div>
                  <div v-else class="status2 mt19">
                    {{ tzData.weight.r3.remark }}
                  </div>
                  <div v-if="tzData.weight.r4.isDown" class="status1 mt19">
                    {{ tzData.weight.r4.remark }}
                  </div>
                  <div v-else class="status2 mt19">
                    {{ tzData.weight.r4.remark }}
                  </div>
                </div>
              </div>
            </div>
            <div class="line mt4"></div>
            <div class="row">
              <img
                class="image1"
                src="../../assets/img/home/home/PES/Pic_SL.png"
              />
              <div class="column_base flex">
                <div class="row mt12 width586">
                  <div class="flag_text">视力</div>
                  <div class="flex"></div>
                  <div v-if="tzData.eyeL" class="avg_text">
                    平均视力：{{ tzData.eyeL.avgValue }}/{{
                      tzData.eyeR.avgValue
                    }}
                  </div>
                </div>
                <div class="row">
                  <div class="column_base">
                    <div class="avg_pf">园所平均视力评分</div>
                    <div
                      class="progressColor mt4"
                      :style="{
                        width: width3 + 'rem',
                        'border-color':
                          score3 < 60
                            ? '#2D8A00'
                            : score3 < 70
                            ? '#AD8600'
                            : score3 < 80
                            ? '#009C80'
                            : '#AD8600',
                        background:
                          score3 < 60
                            ? '#47D800'
                            : score3 < 70
                            ? '#F0BA00'
                            : score3 < 80
                            ? '#00E2BA'
                            : '#FE0101',
                      }"
                    ></div>
                  </div>
                  <div class="fs">{{ score3 }}分</div>
                </div>
                <img
                  v-if="score3 <= 60"
                  class="progress_arrow"
                  :style="{ 'margin-left': width3 - 0.05 + 'rem' }"
                  src="../../assets/img/home/home/PES/Icon_Arrow_G.png"
                />
                <img
                  v-else-if="score3 <= 80"
                  class="progress_arrow"
                  :style="{ 'margin-left': width3 - 0.05 + 'rem' }"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Y.png"
                />
                <img
                  v-else-if="score3 <= 90"
                  class="progress_arrow"
                  :style="{ 'margin-left': width3 - 0.05 + 'rem' }"
                  src="../../assets/img/home/home/PES/Icon_Arrow_B.png"
                />
                <img
                  v-else
                  class="progress_arrow"
                  :style="{ 'margin-left': width3 - 0.05 + 'rem' }"
                  src="../../assets/img/home/home/PES/Icon_Arrow_R.png"
                />
                <img
                  class="progress_line"
                  src="../../assets/img/home/home/PES/Pic_JDT_PJ.png"
                />
              </div>
              <div class="line_v mt17"></div>
              <div class="width213 column_base mt13">
                <div class="row center mrl5 max">
                  <div class="text1 flex">最大值：</div>
                  <div class="text1">{{ tzData.eyeL.best.value }}</div>
                  <img
                    v-if="tzData.eyeL.best.isDown"
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                  />
                  <img
                    v-else
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                  />
                </div>
                <div class="row center mrl5 max mt20">
                  <div class="text1 flex">最小值：</div>
                  <div class="text1">{{ tzData.eyeL.worst.value }}</div>
                  <img
                    v-if="tzData.eyeL.worst.isDown"
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                  />
                  <img
                    v-else
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                  />
                </div>
                <div class="row center mrl5 max mt20">
                  <div class="text1 flex">本期完成率：</div>
                  <div class="text1">{{ tzData.eyeL.completeRate.value }}%</div>
                  <img
                    v-if="tzData.eyeL.completeRate.isDown"
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                  />
                  <img
                    v-else
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                  />
                </div>
                <div class="row center mrl5 max mt20">
                  <div class="text1 flex">本期增长率：</div>
                  <div class="text1">{{ tzData.eyeL.riseRate.value }}%</div>
                  <img
                    v-if="tzData.eyeL.riseRate.isDown"
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                  />
                  <img
                    v-else
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                  />
                </div>
              </div>
              <div class="line_v mt17"></div>
              <div class="width263 row mt13">
                <div class="column_center mrl42">
                  <div class="ys_pos">
                    <div
                      class="pro"
                      :style="{ height: ysHeight3 + 'rem' }"
                    ></div>
                    <div class="bg flex"></div>
                  </div>
                  <div class="ys_text">园所</div>
                </div>
                <div class="column_center mrl13">
                  <div class="ys_pos">
                    <div
                      class="pro"
                      :style="{ height: qgHeight3 + 'rem' }"
                    ></div>
                    <div class="qg_bg flex"></div>
                  </div>
                  <div class="qg_text">全国</div>
                </div>
                <div class="column_end flex">
                  <div v-if="tzData.eyeL.r1.isDown" class="status1">
                    {{ tzData.eyeL.r1.remark }}
                  </div>
                  <div v-else class="status2">
                    {{ tzData.eyeL.r1.remark }}
                  </div>
                  <div v-if="tzData.eyeL.r2.isDown" class="status1 mt19">
                    {{ tzData.eyeL.r2.remark }}
                  </div>
                  <div v-else class="status2 mt19">
                    {{ tzData.eyeL.r2.remark }}
                  </div>
                  <div v-if="tzData.eyeL.r3.isDown" class="status1 mt19">
                    {{ tzData.eyeL.r3.remark }}
                  </div>
                  <div v-else class="status2 mt19">
                    {{ tzData.eyeL.r3.remark }}
                  </div>
                  <div v-if="tzData.eyeL.r4.isDown" class="status1 mt19">
                    {{ tzData.eyeL.r4.remark }}
                  </div>
                  <div v-else class="status2 mt19">
                    {{ tzData.eyeL.r4.remark }}
                  </div>
                </div>
              </div>
            </div>
            <div class="line mt4"></div>
            <div class="row">
              <img
                class="image1"
                src="../../assets/img/home/home/PES/Pic_BZ.png"
              />
              <div class="column_base flex">
                <div class="row mt12 width586">
                  <div class="flag_text">臂展</div>
                  <div class="flex"></div>
                  <div v-if="tzData.arm" class="avg_text">
                    平均臂展：{{ tzData.arm.avgValue }}cm
                  </div>
                </div>
                <div class="row">
                  <div class="column_base">
                    <div class="avg_pf">园所平均臂展评分</div>
                    <div
                      class="progressColor mt4"
                      :style="{
                        width: width4 + 'rem',
                        'border-color':
                          score4 < 60
                            ? '#2D8A00'
                            : score4 < 70
                            ? '#AD8600'
                            : score4 < 80
                            ? '#009C80'
                            : '#AD8600',
                        background:
                          score4 < 60
                            ? '#47D800'
                            : score4 < 70
                            ? '#F0BA00'
                            : score4 < 80
                            ? '#00E2BA'
                            : '#FE0101',
                      }"
                    ></div>
                  </div>
                  <div class="fs">{{ score4 }}分</div>
                </div>
                <img
                  v-if="score4 <= 60"
                  class="progress_arrow"
                  :style="{ 'margin-left': width4 - 0.05 + 'rem' }"
                  src="../../assets/img/home/home/PES/Icon_Arrow_G.png"
                />
                <img
                  v-else-if="score4 <= 80"
                  class="progress_arrow"
                  :style="{ 'margin-left': width4 - 0.05 + 'rem' }"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Y.png"
                />
                <img
                  v-else-if="score4 <= 90"
                  class="progress_arrow"
                  :style="{ 'margin-left': width4 - 0.05 + 'rem' }"
                  src="../../assets/img/home/home/PES/Icon_Arrow_B.png"
                />
                <img
                  v-else
                  class="progress_arrow"
                  :style="{ 'margin-left': width4 - 0.05 + 'rem' }"
                  src="../../assets/img/home/home/PES/Icon_Arrow_R.png"
                />
                <img
                  class="progress_line"
                  src="../../assets/img/home/home/PES/Pic_JDT_PJ.png"
                />
              </div>
              <div class="line_v mt17"></div>
              <div class="width213 column_base mt13">
                <div class="row center mrl5 max">
                  <div class="text1 flex">最大值：</div>
                  <div class="text1">{{ tzData.arm.best.value }}cm</div>
                  <img
                    v-if="tzData.arm.best.isDown"
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                  />
                  <img
                    v-else
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                  />
                </div>
                <div class="row center mrl5 max mt20">
                  <div class="text1 flex">最小值：</div>
                  <div class="text1">{{ tzData.arm.worst.value }}cm</div>
                  <img
                    v-if="tzData.arm.worst.isDown"
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                  />
                  <img
                    v-else
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                  />
                </div>
                <div class="row center mrl5 max mt20">
                  <div class="text1 flex">本期完成率：</div>
                  <div class="text1">{{ tzData.arm.completeRate.value }}%</div>
                  <img
                    v-if="tzData.arm.completeRate.isDown"
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                  />
                  <img
                    v-else
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                  />
                </div>
                <div class="row center mrl5 max mt20">
                  <div class="text1 flex">本期增长率：</div>
                  <div class="text1">{{ tzData.arm.riseRate.value }}%</div>
                  <img
                    v-if="tzData.arm.riseRate.isDown"
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                  />
                  <img
                    v-else
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                  />
                </div>
              </div>
              <div class="line_v mt17"></div>
              <div class="width263 row mt13">
                <div class="column_center mrl42">
                  <div class="ys_pos">
                    <div
                      class="pro"
                      :style="{ height: ysHeight4 + 'rem' }"
                    ></div>
                    <div class="bg flex"></div>
                  </div>
                  <div class="ys_text">园所</div>
                </div>
                <div class="column_center mrl13">
                  <div class="ys_pos">
                    <div
                      class="pro"
                      :style="{ height: qgHeight4 + 'rem' }"
                    ></div>
                    <div class="qg_bg flex"></div>
                  </div>
                  <div class="qg_text">全国</div>
                </div>
                <div class="column_end flex">
                  <div v-if="tzData.arm.r1.isDown" class="status1">
                    {{ tzData.arm.r1.remark }}
                  </div>
                  <div v-else class="status2">
                    {{ tzData.arm.r1.remark }}
                  </div>
                  <div v-if="tzData.arm.r2.isDown" class="status1 mt19">
                    {{ tzData.arm.r2.remark }}
                  </div>
                  <div v-else class="status2 mt19">
                    {{ tzData.arm.r2.remark }}
                  </div>
                  <div v-if="tzData.arm.r3.isDown" class="status1 mt19">
                    {{ tzData.arm.r3.remark }}
                  </div>
                  <div v-else class="status2 mt19">
                    {{ tzData.arm.r3.remark }}
                  </div>
                  <div v-if="tzData.arm.r4.isDown" class="status1 mt19">
                    {{ tzData.arm.r4.remark }}
                  </div>
                  <div v-else class="status2 mt19">
                    {{ tzData.arm.r4.remark }}
                  </div>
                </div>
              </div>
            </div>
            <div class="line mt4"></div>
            <div class="row">
              <img
                class="image1"
                src="../../assets/img/home/home/PES/PIc_FHL.png"
              />
              <div class="column_base flex">
                <div class="row mt12 width586">
                  <div class="flag_text">肺活量</div>
                  <div class="flex"></div>
                  <div v-if="tzData.lung" class="avg_text">
                    平均肺活量： {{ tzData.lung.avgValue }}ml
                  </div>
                </div>
                <div class="row">
                  <div class="column_base">
                    <div class="avg_pf">园所平均肺活量评分</div>
                    <div
                      class="progressColor mt4"
                      :style="{
                        width: width5 + 'rem',
                        'border-color':
                          score5 < 60
                            ? '#2D8A00'
                            : score5 < 70
                            ? '#AD8600'
                            : score5 < 80
                            ? '#009C80'
                            : '#AD8600',
                        background:
                          score5 < 60
                            ? '#47D800'
                            : score5 < 70
                            ? '#F0BA00'
                            : score5 < 80
                            ? '#00E2BA'
                            : '#FE0101',
                      }"
                    ></div>
                  </div>
                  <div class="fs">{{ score5 }}分</div>
                </div>
                <img
                  v-if="score5 <= 60"
                  class="progress_arrow"
                  :style="{ 'margin-left': width5 - 0.05 + 'rem' }"
                  src="../../assets/img/home/home/PES/Icon_Arrow_G.png"
                />
                <img
                  v-else-if="score5 <= 80"
                  class="progress_arrow"
                  :style="{ 'margin-left': width5 - 0.05 + 'rem' }"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Y.png"
                />
                <img
                  v-else-if="score5 <= 90"
                  class="progress_arrow"
                  :style="{ 'margin-left': width5 - 0.05 + 'rem' }"
                  src="../../assets/img/home/home/PES/Icon_Arrow_B.png"
                />
                <img
                  v-else
                  class="progress_arrow"
                  :style="{ 'margin-left': width5 - 0.05 + 'rem' }"
                  src="../../assets/img/home/home/PES/Icon_Arrow_R.png"
                />
                <img
                  class="progress_line"
                  src="../../assets/img/home/home/PES/Pic_JDT_PJ.png"
                />
              </div>
              <div class="line_v mt17"></div>
              <div class="width213 column_base mt13">
                <div class="row center mrl5 max">
                  <div class="text1 flex">最大值：</div>
                  <div class="text1">{{ tzData.lung.best.value }}ml</div>
                  <img
                    v-if="tzData.lung.best.isDown"
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                  />
                  <img
                    v-else
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                  />
                </div>
                <div class="row center mrl5 max mt20">
                  <div class="text1 flex">最小值：</div>
                  <div class="text1">{{ tzData.lung.worst.value }}ml</div>
                  <img
                    v-if="tzData.lung.worst.isDown"
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                  />
                  <img
                    v-else
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                  />
                </div>
                <div class="row center mrl5 max mt20">
                  <div class="text1 flex">本期完成率：</div>
                  <div class="text1">{{ tzData.lung.completeRate.value }}%</div>
                  <img
                    v-if="tzData.lung.completeRate.isDown"
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                  />
                  <img
                    v-else
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                  />
                </div>
                <div class="row center mrl5 max mt20">
                  <div class="text1 flex">本期增长率：</div>
                  <div class="text1">{{ tzData.lung.riseRate.value }}%</div>
                  <img
                    v-if="tzData.lung.riseRate.isDown"
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                  />
                  <img
                    v-else
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                  />
                </div>
              </div>
              <div class="line_v mt17"></div>
              <div class="width263 row mt13">
                <div class="column_center mrl42">
                  <div class="ys_pos">
                    <div
                      class="pro"
                      :style="{ height: ysHeight5 + 'rem' }"
                    ></div>
                    <div class="bg flex"></div>
                  </div>
                  <div class="ys_text">园所</div>
                </div>
                <div class="column_center mrl13">
                  <div class="ys_pos">
                    <div
                      class="pro"
                      :style="{ height: qgHeight5 + 'rem' }"
                    ></div>
                    <div class="qg_bg flex"></div>
                  </div>
                  <div class="qg_text">全国</div>
                </div>
                <div class="column_end flex">
                  <div v-if="tzData.lung.r1.isDown" class="status1">
                    {{ tzData.lung.r1.remark }}
                  </div>
                  <div v-else class="status2">
                    {{ tzData.lung.r1.remark }}
                  </div>
                  <div v-if="tzData.lung.r2.isDown" class="status1 mt19">
                    {{ tzData.lung.r2.remark }}
                  </div>
                  <div v-else class="status2 mt19">
                    {{ tzData.lung.r2.remark }}
                  </div>
                  <div v-if="tzData.lung.r3.isDown" class="status1 mt19">
                    {{ tzData.lung.r3.remark }}
                  </div>
                  <div v-else class="status2 mt19">
                    {{ tzData.lung.r3.remark }}
                  </div>
                  <div v-if="tzData.lung.r4.isDown" class="status1 mt19">
                    {{ tzData.lung.r4.remark }}
                  </div>
                  <div v-else class="status2 mt19">
                    {{ tzData.lung.r4.remark }}
                  </div>
                </div>
              </div>
            </div>
            <div class="line mt4"></div>
            <div class="row">
              <img
                class="image1"
                src="../../assets/img/home/home/PES/Pic_XW.png"
              />
              <div class="column_base flex">
                <div class="row mt12 width586">
                  <div class="flag_text">胸围</div>
                  <div class="flex"></div>
                  <div v-if="tzData.bust" class="avg_text">
                    平均胸围：{{ tzData.bust.avgValue }}cm
                  </div>
                </div>
                <div class="row">
                  <div class="column_base">
                    <div class="avg_pf">园所平均胸围评分</div>
                    <div
                      class="progressColor mt4"
                      :style="{
                        width: width6 + 'rem',
                        'border-color':
                          score6 < 60
                            ? '#2D8A00'
                            : score6 < 70
                            ? '#AD8600'
                            : score6 < 80
                            ? '#009C80'
                            : '#AD8600',
                        background:
                          score6 < 60
                            ? '#47D800'
                            : score6 < 70
                            ? '#F0BA00'
                            : score6 < 80
                            ? '#00E2BA'
                            : '#FE0101',
                      }"
                    ></div>
                  </div>
                  <div class="fs">{{ score6 }}分</div>
                </div>
                <img
                  v-if="score6 <= 60"
                  class="progress_arrow"
                  :style="{ 'margin-left': width6 - 0.05 + 'rem' }"
                  src="../../assets/img/home/home/PES/Icon_Arrow_G.png"
                />
                <img
                  v-else-if="score6 <= 80"
                  class="progress_arrow"
                  :style="{ 'margin-left': width6 - 0.05 + 'rem' }"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Y.png"
                />
                <img
                  v-else-if="score6 <= 90"
                  class="progress_arrow"
                  :style="{ 'margin-left': width6 - 0.05 + 'rem' }"
                  src="../../assets/img/home/home/PES/Icon_Arrow_B.png"
                />
                <img
                  v-else
                  class="progress_arrow"
                  :style="{ 'margin-left': width6 - 0.05 + 'rem' }"
                  src="../../assets/img/home/home/PES/Icon_Arrow_R.png"
                />
                <img
                  class="progress_line"
                  src="../../assets/img/home/home/PES/Pic_JDT_PJ.png"
                />
              </div>
              <div class="line_v mt17"></div>
              <div class="width213 column_base mt13">
                <div class="row center mrl5 max">
                  <div class="text1 flex">最大值：</div>
                  <div class="text1">{{ tzData.bust.best.value }}cm</div>
                  <img
                    v-if="tzData.bust.best.isDown"
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                  />
                  <img
                    v-else
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                  />
                </div>
                <div class="row center mrl5 max mt20">
                  <div class="text1 flex">最小值：</div>
                  <div class="text1">{{ tzData.bust.worst.value }}cm</div>
                  <img
                    v-if="tzData.bust.worst.isDown"
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                  />
                  <img
                    v-else
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                  />
                </div>
                <div class="row center mrl5 max mt20">
                  <div class="text1 flex">本期完成率：</div>
                  <div class="text1">{{ tzData.bust.completeRate.value }}%</div>
                  <img
                    v-if="tzData.bust.completeRate.isDown"
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                  />
                  <img
                    v-else
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                  />
                </div>
                <div class="row center mrl5 max mt20">
                  <div class="text1 flex">本期增长率：</div>
                  <div class="text1">{{ tzData.bust.riseRate.value }}%</div>
                  <img
                    v-if="tzData.bust.riseRate.isDown"
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                  />
                  <img
                    v-else
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                  />
                </div>
              </div>
              <div class="line_v mt17"></div>
              <div class="width263 row mt13">
                <div class="column_center mrl42">
                  <div class="ys_pos">
                    <div
                      class="pro"
                      :style="{ height: ysHeight6 + 'rem' }"
                    ></div>
                    <div class="bg flex"></div>
                  </div>
                  <div class="ys_text">园所</div>
                </div>
                <div class="column_center mrl13">
                  <div class="ys_pos">
                    <div
                      class="pro"
                      :style="{ height: qgHeight6 + 'rem' }"
                    ></div>
                    <div class="qg_bg flex"></div>
                  </div>
                  <div class="qg_text">全国</div>
                </div>
                <div class="column_end flex">
                  <div v-if="tzData.bust.r1.isDown" class="status1">
                    {{ tzData.bust.r1.remark }}
                  </div>
                  <div v-else class="status2">
                    {{ tzData.bust.r1.remark }}
                  </div>
                  <div v-if="tzData.bust.r2.isDown" class="status1 mt19">
                    {{ tzData.bust.r2.remark }}
                  </div>
                  <div v-else class="status2 mt19">
                    {{ tzData.bust.r2.remark }}
                  </div>
                  <div v-if="tzData.bust.r3.isDown" class="status1 mt19">
                    {{ tzData.bust.r3.remark }}
                  </div>
                  <div v-else class="status2 mt19">
                    {{ tzData.bust.r3.remark }}
                  </div>
                  <div v-if="tzData.bust.r4.isDown" class="status1 mt19">
                    {{ tzData.bust.r4.remark }}
                  </div>
                  <div v-else class="status2 mt19">
                    {{ tzData.bust.r4.remark }}
                  </div>
                </div>
              </div>
            </div>
            <div class="line mt4"></div>
            <div class="row">
              <img
                class="image1"
                src="../../assets/img/home/home/PES/Pic_TQQ.png"
              />
              <div class="column_base flex">
                <div class="row mt12 width586">
                  <div class="flag_text">体前屈</div>
                  <div class="flex"></div>
                  <div v-if="tzData.flexion" class="avg_text">
                    平均体前屈：{{ tzData.flexion.avgValue }}cm
                  </div>
                </div>
                <div class="row">
                  <div class="column_base">
                    <div class="avg_pf">园所平均体前屈评分</div>
                    <div
                      class="progressColor mt4"
                      :style="{
                        width: width7 + 'rem',
                        'border-color':
                          score7 < 60
                            ? '#2D8A00'
                            : score7 < 70
                            ? '#AD8600'
                            : score7 < 80
                            ? '#009C80'
                            : '#AD8600',
                        background:
                          score7 < 60
                            ? '#47D800'
                            : score7 < 70
                            ? '#F0BA00'
                            : score7 < 80
                            ? '#00E2BA'
                            : '#FE0101',
                      }"
                    ></div>
                  </div>
                  <div class="fs">{{ score7 }}分</div>
                </div>
                <img
                  v-if="score7 <= 60"
                  class="progress_arrow"
                  :style="{ 'margin-left': width7 - 0.05 + 'rem' }"
                  src="../../assets/img/home/home/PES/Icon_Arrow_G.png"
                />
                <img
                  v-else-if="score7 <= 80"
                  class="progress_arrow"
                  :style="{ 'margin-left': width7 - 0.05 + 'rem' }"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Y.png"
                />
                <img
                  v-else-if="score7 <= 90"
                  class="progress_arrow"
                  :style="{ 'margin-left': width7 - 0.05 + 'rem' }"
                  src="../../assets/img/home/home/PES/Icon_Arrow_B.png"
                />
                <img
                  v-else
                  class="progress_arrow"
                  :style="{ 'margin-left': width7 - 0.05 + 'rem' }"
                  src="../../assets/img/home/home/PES/Icon_Arrow_R.png"
                />
                <img
                  class="progress_line"
                  src="../../assets/img/home/home/PES/Pic_JDT_PJ.png"
                />
              </div>
              <div class="line_v mt17"></div>
              <div class="width213 column_base mt13">
                <div class="row center mrl5 max">
                  <div class="text1 flex">最大值：</div>
                  <div class="text1">{{ tzData.flexion.best.value }}cm</div>
                  <img
                    v-if="tzData.flexion.best.isDown"
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                  />
                  <img
                    v-else
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                  />
                </div>
                <div class="row center mrl5 max mt20">
                  <div class="text1 flex">最小值：</div>
                  <div class="text1">{{ tzData.flexion.worst.value }}cm</div>
                  <img
                    v-if="tzData.flexion.worst.isDown"
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                  />
                  <img
                    v-else
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                  />
                </div>
                <div class="row center mrl5 max mt20">
                  <div class="text1 flex">本期完成率：</div>
                  <div class="text1">
                    {{ tzData.flexion.completeRate.value }}%
                  </div>
                  <img
                    v-if="tzData.flexion.completeRate.isDown"
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                  />
                  <img
                    v-else
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                  />
                </div>
                <div class="row center mrl5 max mt20">
                  <div class="text1 flex">本期增长率：</div>
                  <div class="text1">{{ tzData.flexion.riseRate.value }}%</div>
                  <img
                    v-if="tzData.flexion.riseRate.isDown"
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                  />
                  <img
                    v-else
                    class="arrow_v"
                    src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                  />
                </div>
              </div>
              <div class="line_v mt17"></div>
              <div class="width263 row mt13">
                <div class="column_center mrl42">
                  <div class="ys_pos">
                    <div
                      class="pro"
                      :style="{ height: ysHeight7 + 'rem' }"
                    ></div>
                    <div class="bg flex"></div>
                  </div>
                  <div class="ys_text">园所</div>
                </div>
                <div class="column_center mrl13">
                  <div class="ys_pos">
                    <div
                      class="pro"
                      :style="{ height: qgHeight7 + 'rem' }"
                    ></div>
                    <div class="qg_bg flex"></div>
                  </div>
                  <div class="qg_text">全国</div>
                </div>
                <div class="column_end flex">
                  <div v-if="tzData.flexion.r1.isDown" class="status1">
                    {{ tzData.flexion.r1.remark }}
                  </div>
                  <div v-else class="status2">
                    {{ tzData.flexion.r1.remark }}
                  </div>
                  <div v-if="tzData.flexion.r2.isDown" class="status1 mt19">
                    {{ tzData.flexion.r2.remark }}
                  </div>
                  <div v-else class="status2 mt19">
                    {{ tzData.flexion.r2.remark }}
                  </div>
                  <div v-if="tzData.flexion.r3.isDown" class="status1 mt19">
                    {{ tzData.flexion.r3.remark }}
                  </div>
                  <div v-else class="status2 mt19">
                    {{ tzData.flexion.r3.remark }}
                  </div>
                  <div v-if="tzData.flexion.r4.isDown" class="status1 mt19">
                    {{ tzData.flexion.r4.remark }}
                  </div>
                  <div v-else class="status2 mt19">
                    {{ tzData.flexion.r4.remark }}
                  </div>
                </div>
              </div>
            </div>
            <div class="line mt4"></div>
          </div>
          <div class="top_lan mt137">
            <div class="title4">综合报告</div>
          </div>
          <div class="line mt33"></div>
          <div class="bg_row widthMax h300">
            <div class="column_center">
              <div class="pre">上<br /><br />期</div>
              <div class="next">下<br /><br />期</div>
            </div>
            <div id="tzBarChart" class="barChart"></div>
            <div class="flex"></div>
            <div class="column_base width624">
              <div class="fx">同期对比分析：</div>
              <div class="row center mt13">
                <div class="color1"></div>
                <div class="value">空值分析：{{ chartValue.nullRemark }}</div>
              </div>
              <div class="row center mt5">
                <div class="color2"></div>
                <div class="value">较差分析：{{ chartValue.badRemark }}</div>
              </div>
              <div class="row center mt5">
                <div class="color3"></div>
                <div class="value">优势分析：{{ chartValue.fineRemark }}</div>
              </div>
              <div class="row center mt5">
                <div class="color4"></div>
                <div class="value">整体分析：{{ chartValue.allRemark }}</div>
              </div>
            </div>
          </div>
          <div class="line"></div>
          <div class="bg_row widthMax h300">
            <div class="column_center">
              <div class="pre">全<br /><br />国</div>
              <div class="next">本<br />园<br />所</div>
            </div>
            <div class="row mrl49">
              <div class="column_center">
                <div class="row">
                  <div class="left_pos">
                    <div class="left_bg"></div>
                    <div class="column_base h180 abs">
                      <div class="flex"></div>
                      <div class="left_value">{{ fxysValue1 }}</div>
                      <div class="left_y"></div>
                      <div
                        class="left_v"
                        :style="{ height: fxysHeight1 + 'rem' }"
                      ></div>
                    </div>
                  </div>
                  <div class="left_pos mrl34">
                    <div class="left_bg"></div>
                    <div class="column_base h180 abs">
                      <div class="flex"></div>
                      <div class="right_value">{{ fxqgValue1 }}</div>
                      <div class="right_y"></div>
                      <div
                        class="right_v"
                        :style="{ height: fxqgHeight1 + 'rem' }"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="circle_left"></div>
                  <div class="circle_right"></div>
                </div>
                <div class="bot_text">身高</div>
              </div>
              <div class="column_center mrl34">
                <div class="row">
                  <div class="left_pos">
                    <div class="left_bg"></div>
                    <div class="column_base h180 abs">
                      <div class="flex"></div>
                      <div class="left_value">{{ fxysValue2 }}</div>
                      <div class="left_y"></div>
                      <div
                        class="left_v"
                        :style="{ height: fxysHeight2 + 'rem' }"
                      ></div>
                    </div>
                  </div>
                  <div class="left_pos mrl34">
                    <div class="left_bg"></div>
                    <div class="column_base h180 abs">
                      <div class="flex"></div>
                      <div class="right_value">{{ fxqgValue2 }}</div>
                      <div class="right_y"></div>
                      <div
                        class="right_v"
                        :style="{ height: fxqgHeight2 + 'rem' }"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="circle_left"></div>
                  <div class="circle_right"></div>
                </div>
                <div class="bot_text">体重</div>
              </div>
              <div class="column_center mrl34">
                <div class="row">
                  <div class="left_pos">
                    <div class="left_bg"></div>
                    <div class="column_base h180 abs">
                      <div class="flex"></div>
                      <div class="left_value">{{ fxysValue3 }}</div>
                      <div class="left_y"></div>
                      <div
                        class="left_v"
                        :style="{ height: fxysHeight3 + 'rem' }"
                      ></div>
                    </div>
                  </div>
                  <div class="left_pos mrl34">
                    <div class="left_bg"></div>
                    <div class="column_base h180 abs">
                      <div class="flex"></div>
                      <div class="right_value">{{ fxqgValue3 }}</div>
                      <div class="right_y"></div>
                      <div
                        class="right_v"
                        :style="{ height: fxqgHeight3 + 'rem' }"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="circle_left"></div>
                  <div class="circle_right"></div>
                </div>
                <div class="bot_text">视力</div>
              </div>
              <div class="column_center mrl34">
                <div class="row">
                  <div class="left_pos">
                    <div class="left_bg"></div>
                    <div class="column_base h180 abs">
                      <div class="flex"></div>
                      <div class="left_value">{{ fxysValue4 }}</div>
                      <div class="left_y"></div>
                      <div
                        class="left_v"
                        :style="{ height: fxysHeight4 + 'rem' }"
                      ></div>
                    </div>
                  </div>
                  <div class="left_pos mrl34">
                    <div class="left_bg"></div>
                    <div class="column_base h180 abs">
                      <div class="flex"></div>
                      <div class="right_value">{{ fxqgValue4 }}</div>
                      <div class="right_y"></div>
                      <div
                        class="right_v"
                        :style="{ height: fxqgHeight4 + 'rem' }"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="circle_left"></div>
                  <div class="circle_right"></div>
                </div>
                <div class="bot_text">臂展</div>
              </div>
              <div class="column_center mrl34">
                <div class="row">
                  <div class="left_pos">
                    <div class="left_bg"></div>
                    <div class="column_base h180 abs">
                      <div class="flex"></div>
                      <div class="left_value">{{ fxysValue5 }}</div>
                      <div class="left_y"></div>
                      <div
                        class="left_v"
                        :style="{ height: fxysHeight5 + 'rem' }"
                      ></div>
                    </div>
                  </div>
                  <div class="left_pos mrl34">
                    <div class="left_bg"></div>
                    <div class="column_base h180 abs">
                      <div class="flex"></div>
                      <div class="right_value">{{ fxqgValue5 }}</div>
                      <div class="right_y"></div>
                      <div
                        class="right_v"
                        :style="{ height: fxqgHeight5 + 'rem' }"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="circle_left"></div>
                  <div class="circle_right"></div>
                </div>
                <div class="bot_text">肺活量</div>
              </div>
              <div class="column_center mrl34">
                <div class="row">
                  <div class="left_pos">
                    <div class="left_bg"></div>
                    <div class="column_base h180 abs">
                      <div class="flex"></div>
                      <div class="left_value">{{ fxysValue6 }}</div>
                      <div class="left_y"></div>
                      <div
                        class="left_v"
                        :style="{ height: fxysHeight6 + 'rem' }"
                      ></div>
                    </div>
                  </div>
                  <div class="left_pos mrl34">
                    <div class="left_bg"></div>
                    <div class="column_base h180 abs">
                      <div class="flex"></div>
                      <div class="right_value">{{ fxqgValue6 }}</div>
                      <div class="right_y"></div>
                      <div
                        class="right_v"
                        :style="{ height: fxqgHeight6 + 'rem' }"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="circle_left"></div>
                  <div class="circle_right"></div>
                </div>
                <div class="bot_text">胸围</div>
              </div>
              <div class="column_center mrl34">
                <div class="row">
                  <div class="left_pos">
                    <div class="left_bg"></div>
                    <div class="column_base h180 abs">
                      <div class="flex"></div>
                      <div class="left_value">{{ fxysValue7 }}</div>
                      <div class="left_y"></div>
                      <div
                        class="left_v"
                        :style="{ height: fxysHeight7 + 'rem' }"
                      ></div>
                    </div>
                  </div>
                  <div class="left_pos mrl34">
                    <div class="left_bg"></div>
                    <div class="column_base h180 abs">
                      <div class="flex"></div>
                      <div class="right_value">{{ fxqgValue7 }}</div>
                      <div class="right_y"></div>
                      <div
                        class="right_v"
                        :style="{ height: fxqgHeight7 + 'rem' }"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="circle_left"></div>
                  <div class="circle_right"></div>
                </div>
                <div class="bot_text">体前屈</div>
              </div>
            </div>
            <div class="flex"></div>
            <div class="column_base width624">
              <div class="fx">平均值全国对比：</div>
              <div class="row center mt13">
                <div class="color1"></div>
                <div class="value">空值分析：{{ chartValue.nullRemark }}</div>
              </div>
              <div class="row center mt5">
                <div class="color2"></div>
                <div class="value">较差分析：{{ chartValue.badRemark }}</div>
              </div>
              <div class="row center mt5">
                <div class="color3"></div>
                <div class="value">优势分析：{{ chartValue.fineRemark }}</div>
              </div>
              <div class="row center mt5">
                <div class="color4"></div>
                <div class="value">整体分析：{{ chartValue.allRemark }}</div>
              </div>
            </div>
          </div>
          <div class="line"></div>
          <div class="bg_row widthMax h300">
            <div class="column_center">
              <div class="pre">全<br /><br />国</div>
              <div class="next">本<br />园<br />所</div>
            </div>
            <div class="column_base mrl49">
              <div id="tzLineChart" class="tzLineChart"></div>
              <div class="center width504 row">
                <div class="flex center">
                  <div class="bot_text">身高</div>
                </div>
                <div class="flex center">
                  <div class="bot_text">体重</div>
                </div>
                <div class="flex center">
                  <div class="bot_text">视力</div>
                </div>
                <div class="flex center">
                  <div class="bot_text">臂展</div>
                </div>
                <div class="flex center">
                  <div class="bot_text">肺活量</div>
                </div>
                <div class="flex center">
                  <div class="bot_text">胸围</div>
                </div>
                <div class="flex center">
                  <div class="bot_text">体前屈</div>
                </div>
              </div>
            </div>
            <div class="flex"></div>
            <div class="column_base width624">
              <div class="fx">增长率全国对比：</div>
              <div class="row center mt13">
                <div class="color1"></div>
                <div class="value">空值分析：{{ chartValue.nullRemark }}</div>
              </div>
              <div class="row center mt5">
                <div class="color2"></div>
                <div class="value">较差分析：{{ chartValue.badRemark }}</div>
              </div>
              <div class="row center mt5">
                <div class="color3"></div>
                <div class="value">优势分析：{{ chartValue.fineRemark }}</div>
              </div>
              <div class="row center mt5">
                <div class="color4"></div>
                <div class="value">整体分析：{{ chartValue.allRemark }}</div>
              </div>
            </div>
          </div>
          <div class="line"></div>
          <div class="bg_row widthMax h300">
            <div class="column_center">
              <div class="abc1">需努力</div>
              <div class="abc2">合格</div>
              <div class="abc3">良好</div>
              <div class="abc4">优秀</div>
            </div>
            <div class="column_base">
              <div id="tzYbChart" class="tzYbChart"></div>
            </div>
            <div class="flex"></div>
            <div class="column_base width624">
              <div class="fx">优秀率对比：</div>
              <div class="row center mt13">
                <div class="color1"></div>
                <div class="value">空值分析：{{ chartValue.nullRemark }}</div>
              </div>
              <div class="row center mt5">
                <div class="color2"></div>
                <div class="value">较差分析：{{ chartValue.badRemark }}</div>
              </div>
              <div class="row center mt5">
                <div class="color3"></div>
                <div class="value">优势分析：{{ chartValue.fineRemark }}</div>
              </div>
              <div class="row center mt5">
                <div class="color4"></div>
                <div class="value">整体分析：{{ chartValue.allRemark }}</div>
              </div>
            </div>
          </div>
          <div class="line"></div>
        </div>
        <div v-else class="width1242">
          <div v-if="sportData.run!=null" class="width1242">
<div class="row">
            <img
              class="image1"
              src="../../assets/img/home/home/PES/Pic_Run.png"
            />
            <div class="column_base flex">
              <div class="row mt12 width586">
                <div class="flag_text">跑动</div>
                <div class="flex"></div>
                <div v-if="sportData.run" class="avg_text">
                  平均跑动：{{ sportData.run.avgValue }}'
                </div>
              </div>
              <div class="row">
                <div class="column_base">
                  <div class="avg_pf">园所平均跑动评分</div>
                  <div
                    class="progressColor mt4"
                    :style="{
                      width: s_width1 + 'rem',
                      'border-color':
                        s_score1 < 60
                          ? '#2D8A00'
                          : s_score1 < 70
                          ? '#AD8600'
                          : s_score1 < 80
                          ? '#009C80'
                          : '#AD8600',
                      background:
                        s_score1 < 60
                          ? '#47D800'
                          : s_score1 < 70
                          ? '#F0BA00'
                          : s_score1 < 80
                          ? '#00E2BA'
                          : '#FE0101',
                    }"
                  ></div>
                </div>
                <div class="fs">{{ s_score1 }}分</div>
              </div>
              <img
                v-if="s_score1 <= 60"
                class="progress_arrow"
                :style="{ 'margin-left': s_width1 - 0.05 + 'rem' }"
                src="../../assets/img/home/home/PES/Icon_Arrow_G.png"
              />
              <img
                v-else-if="s_score1 <= 80"
                class="progress_arrow"
                :style="{ 'margin-left': s_width1 - 0.05 + 'rem' }"
                src="../../assets/img/home/home/PES/Icon_Arrow_Y.png"
              />
              <img
                v-else-if="s_score1 <= 90"
                class="progress_arrow"
                :style="{ 'margin-left': s_width1 - 0.05 + 'rem' }"
                src="../../assets/img/home/home/PES/Icon_Arrow_B.png"
              />
              <img
                v-else
                class="progress_arrow"
                :style="{ 'margin-left': s_width1 - 0.05 + 'rem' }"
                src="../../assets/img/home/home/PES/Icon_Arrow_R.png"
              />
              <img
                class="progress_line"
                src="../../assets/img/home/home/PES/Pic_JDT_PJ.png"
              />
            </div>
            <div class="line_v mt17"></div>
            <div class="width213 column_base mt13">
              <div class="row center mrl5 max">
                <div class="text1 flex">最快速度：</div>
                <div class="text1">{{ sportData.run.best.value }}'</div>
                <img
                  v-if="sportData.run.best.isDown"
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                />
                <img
                  v-else
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                />
              </div>
              <div class="row center mrl5 max mt20">
                <div class="text1 flex">最慢速度：</div>
                <div class="text1">{{ sportData.run.worst.value }}'</div>
                <img
                  v-if="sportData.run.worst.isDown"
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                />
                <img
                  v-else
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                />
              </div>
              <div class="row center mrl5 max mt20">
                <div class="text1 flex">本期完成率：</div>
                <div class="text1">{{ sportData.run.completeRate.value }}%</div>
                <img
                  v-if="sportData.run.completeRate.isDown"
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                />
                <img
                  v-else
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                />
              </div>
              <div class="row center mrl5 max mt20">
                <div class="text1 flex">本期增长率：</div>
                <div class="text1">{{ sportData.run.riseRate.value }}%</div>
                <img
                  v-if="sportData.run.riseRate.isDown"
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                />
                <img
                  v-else
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                />
              </div>
            </div>
            <div class="line_v mt17"></div>
            <div class="width263 row mt13">
              <div class="column_center mrl42">
                <div class="ys_pos">
                  <div
                    class="pro"
                    :style="{ height: s_ysHeight1 + 'rem' }"
                  ></div>
                  <div class="bg flex"></div>
                </div>
                <div class="ys_text">园所</div>
              </div>
              <div class="column_center mrl13">
                <div class="ys_pos">
                  <div
                    class="pro"
                    :style="{ height: s_qgHeight1 + 'rem' }"
                  ></div>
                  <div class="qg_bg flex"></div>
                </div>
                <div class="qg_text">全国</div>
              </div>
              <div class="column_end flex">
                <div v-if="sportData.run.r1.isDown" class="status1">
                  {{ sportData.run.r1.remark }}
                </div>
                <div v-else class="status2">
                  {{ sportData.run.r1.remark }}
                </div>
                <div v-if="sportData.run.r2.isDown" class="status1 mt19">
                  {{ sportData.run.r2.remark }}
                </div>
                <div v-else class="status2 mt19">
                  {{ sportData.run.r2.remark }}
                </div>
                <div v-if="sportData.run.r3.isDown" class="status1 mt19">
                  {{ sportData.run.r3.remark }}
                </div>
                <div v-else class="status2 mt19">
                  {{ sportData.run.r3.remark }}
                </div>
                <div v-if="sportData.run.r4.isDown" class="status1 mt19">
                  {{ sportData.run.r4.remark }}
                </div>
                <div v-else class="status2 mt19">
                  {{ sportData.run.r4.remark }}
                </div>
              </div>
            </div>
          </div>
          <div class="line mt4"></div>
          <div class="row">
            <img
              class="image1"
              src="../../assets/img/home/home/PES/Pic_Jump.png"
            />
            <div class="column_base flex">
              <div class="row mt12 width586">
                <div class="flag_text">跳跃</div>
                <div class="flex"></div>
                <div v-if="sportData.jump" class="avg_text">
                  平均跳跃：{{ sportData.jump.avgValue }}'
                </div>
              </div>
              <div class="row">
                <div class="column_base">
                  <div class="avg_pf">园所平均跳跃评分</div>
                  <div
                    class="progressColor mt4"
                    :style="{
                      width: s_width2 + 'rem',
                      'border-color':
                        s_score2 < 60
                          ? '#2D8A00'
                          : s_score2 < 70
                          ? '#AD8600'
                          : s_score2 < 80
                          ? '#009C80'
                          : '#AD8600',
                      background:
                        s_score2 < 60
                          ? '#47D800'
                          : s_score2 < 70
                          ? '#F0BA00'
                          : s_score2 < 80
                          ? '#00E2BA'
                          : '#FE0101',
                    }"
                  ></div>
                </div>
                <div class="fs">{{ s_score2 }}分</div>
              </div>
              <img
                v-if="s_score2 <= 60"
                class="progress_arrow"
                :style="{ 'margin-left': s_width2 - 0.05 + 'rem' }"
                src="../../assets/img/home/home/PES/Icon_Arrow_G.png"
              />
              <img
                v-else-if="s_score2 <= 80"
                class="progress_arrow"
                :style="{ 'margin-left': s_width2 - 0.05 + 'rem' }"
                src="../../assets/img/home/home/PES/Icon_Arrow_Y.png"
              />
              <img
                v-else-if="s_score2 <= 90"
                class="progress_arrow"
                :style="{ 'margin-left': s_width2 - 0.05 + 'rem' }"
                src="../../assets/img/home/home/PES/Icon_Arrow_B.png"
              />
              <img
                v-else
                class="progress_arrow"
                :style="{ 'margin-left': s_width2 - 0.05 + 'rem' }"
                src="../../assets/img/home/home/PES/Icon_Arrow_R.png"
              />
              <img
                class="progress_line"
                src="../../assets/img/home/home/PES/Pic_JDT_PJ.png"
              />
            </div>
            <div class="line_v mt17"></div>
            <div class="width213 column_base mt13">
              <div class="row center mrl5 max">
                <div class="text1 flex">最快速度：</div>
                <div class="text1">{{ sportData.jump.best.value }}'</div>
                <img
                  v-if="sportData.jump.best.isDown"
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                />
                <img
                  v-else
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                />
              </div>
              <div class="row center mrl5 max mt20">
                <div class="text1 flex">最慢速度：</div>
                <div class="text1">{{ sportData.jump.worst.value }}'</div>
                <img
                  v-if="sportData.jump.worst.isDown"
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                />
                <img
                  v-else
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                />
              </div>
              <div class="row center mrl5 max mt20">
                <div class="text1 flex">本期完成率：</div>
                <div class="text1">
                  {{ sportData.jump.completeRate.value }}%
                </div>
                <img
                  v-if="sportData.jump.completeRate.isDown"
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                />
                <img
                  v-else
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                />
              </div>
              <div class="row center mrl5 max mt20">
                <div class="text1 flex">本期增长率：</div>
                <div class="text1">{{ sportData.jump.riseRate.value }}%</div>
                <img
                  v-if="sportData.jump.riseRate.isDown"
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                />
                <img
                  v-else
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                />
              </div>
            </div>
            <div class="line_v mt17"></div>
            <div class="width263 row mt13">
              <div class="column_center mrl42">
                <div class="ys_pos">
                  <div
                    class="pro"
                    :style="{ height: s_ysHeight2 + 'rem' }"
                  ></div>
                  <div class="bg flex"></div>
                </div>
                <div class="ys_text">园所</div>
              </div>
              <div class="column_center mrl13">
                <div class="ys_pos">
                  <div
                    class="pro"
                    :style="{ height: s_qgHeight2 + 'rem' }"
                  ></div>
                  <div class="qg_bg flex"></div>
                </div>
                <div class="qg_text">全国</div>
              </div>
              <div class="column_end flex">
                <div v-if="sportData.jump.r1.isDown" class="status1">
                  {{ sportData.jump.r1.remark }}
                </div>
                <div v-else class="status2">
                  {{ sportData.jump.r1.remark }}
                </div>
                <div v-if="sportData.jump.r2.isDown" class="status1 mt19">
                  {{ sportData.jump.r2.remark }}
                </div>
                <div v-else class="status2 mt19">
                  {{ sportData.jump.r2.remark }}
                </div>
                <div v-if="sportData.jump.r3.isDown" class="status1 mt19">
                  {{ sportData.jump.r3.remark }}
                </div>
                <div v-else class="status2 mt19">
                  {{ sportData.jump.r3.remark }}
                </div>
                <div v-if="sportData.jump.r4.isDown" class="status1 mt19">
                  {{ sportData.jump.r4.remark }}
                </div>
                <div v-else class="status2 mt19">
                  {{ sportData.jump.r4.remark }}
                </div>
              </div>
            </div>
          </div>
          <div class="line mt4"></div>
          <div class="row">
            <img
              class="image1"
              src="../../assets/img/home/home/PES/Pic_MJ.png"
            />
            <div class="column_base flex">
              <div class="row mt12 width586">
                <div class="flag_text">敏捷</div>
                <div class="flex"></div>
                <div v-if="sportData.agile" class="avg_text">
                  平均敏捷：{{ sportData.agile.avgValue }}'
                </div>
              </div>
              <div class="row">
                <div class="column_base">
                  <div class="avg_pf">园所平均敏捷评分</div>
                  <div
                    class="progressColor mt4"
                    :style="{
                      width: s_width3 + 'rem',
                      'border-color':
                        s_score3 < 60
                          ? '#2D8A00'
                          : s_score3 < 70
                          ? '#AD8600'
                          : s_score3 < 80
                          ? '#009C80'
                          : '#AD8600',
                      background:
                        s_score3 < 60
                          ? '#47D800'
                          : s_score3 < 70
                          ? '#F0BA00'
                          : s_score3 < 80
                          ? '#00E2BA'
                          : '#FE0101',
                    }"
                  ></div>
                </div>
                <div class="fs">{{ s_score3 }}分</div>
              </div>
              <img
                v-if="s_score3 <= 60"
                class="progress_arrow"
                :style="{ 'margin-left': s_width3 - 0.05 + 'rem' }"
                src="../../assets/img/home/home/PES/Icon_Arrow_G.png"
              />
              <img
                v-else-if="s_score3 <= 80"
                class="progress_arrow"
                :style="{ 'margin-left': s_width3 - 0.05 + 'rem' }"
                src="../../assets/img/home/home/PES/Icon_Arrow_Y.png"
              />
              <img
                v-else-if="s_score3 <= 90"
                class="progress_arrow"
                :style="{ 'margin-left': s_width3 - 0.05 + 'rem' }"
                src="../../assets/img/home/home/PES/Icon_Arrow_B.png"
              />
              <img
                v-else
                class="progress_arrow"
                :style="{ 'margin-left': s_width3 - 0.05 + 'rem' }"
                src="../../assets/img/home/home/PES/Icon_Arrow_R.png"
              />
              <img
                class="progress_line"
                src="../../assets/img/home/home/PES/Pic_JDT_PJ.png"
              />
            </div>
            <div class="line_v mt17"></div>
            <div class="width213 column_base mt13">
              <div class="row center mrl5 max">
                <div class="text1 flex">最快速度：</div>
                <div class="text1">{{ sportData.agile.best.value }}'</div>
                <img
                  v-if="sportData.agile.best.isDown"
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                />
                <img
                  v-else
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                />
              </div>
              <div class="row center mrl5 max mt20">
                <div class="text1 flex">最慢速度：</div>
                <div class="text1">{{ sportData.agile.worst.value }}'</div>
                <img
                  v-if="sportData.agile.worst.isDown"
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                />
                <img
                  v-else
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                />
              </div>
              <div class="row center mrl5 max mt20">
                <div class="text1 flex">本期完成率：</div>
                <div class="text1">
                  {{ sportData.agile.completeRate.value }}%
                </div>
                <img
                  v-if="sportData.agile.completeRate.isDown"
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                />
                <img
                  v-else
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                />
              </div>
              <div class="row center mrl5 max mt20">
                <div class="text1 flex">本期增长率：</div>
                <div class="text1">{{ sportData.agile.riseRate.value }}%</div>
                <img
                  v-if="sportData.agile.riseRate.isDown"
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                />
                <img
                  v-else
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                />
              </div>
            </div>
            <div class="line_v mt17"></div>
            <div class="width263 row mt13">
              <div class="column_center mrl42">
                <div class="ys_pos">
                  <div
                    class="pro"
                    :style="{ height: s_ysHeight3 + 'rem' }"
                  ></div>
                  <div class="bg flex"></div>
                </div>
                <div class="ys_text">园所</div>
              </div>
              <div class="column_center mrl13">
                <div class="ys_pos">
                  <div
                    class="pro"
                    :style="{ height: s_qgHeight3 + 'rem' }"
                  ></div>
                  <div class="qg_bg flex"></div>
                </div>
                <div class="qg_text">全国</div>
              </div>
              <div class="column_end flex">
                <div v-if="sportData.agile.r1.isDown" class="status1">
                  {{ sportData.agile.r1.remark }}
                </div>
                <div v-else class="status2">
                  {{ sportData.agile.r1.remark }}
                </div>
                <div v-if="sportData.agile.r2.isDown" class="status1 mt19">
                  {{ sportData.agile.r2.remark }}
                </div>
                <div v-else class="status2 mt19">
                  {{ sportData.agile.r2.remark }}
                </div>
                <div v-if="sportData.agile.r3.isDown" class="status1 mt19">
                  {{ sportData.agile.r3.remark }}
                </div>
                <div v-else class="status2 mt19">
                  {{ sportData.agile.r3.remark }}
                </div>
                <div v-if="sportData.agile.r4.isDown" class="status1 mt19">
                  {{ sportData.agile.r4.remark }}
                </div>
                <div v-else class="status2 mt19">
                  {{ sportData.agile.r4.remark }}
                </div>
              </div>
            </div>
          </div>
          <div class="line mt4"></div>
          <div class="row">
            <img
              class="image1"
              src="../../assets/img/home/home/PES/Pic_PH.png"
            />
            <div class="column_base flex">
              <div class="row mt12 width586">
                <div class="flag_text">平衡</div>
                <div class="flex"></div>
                <div v-if="sportData.balance" class="avg_text">
                  平均平衡：{{ sportData.balance.avgValue }}'
                </div>
              </div>
              <div class="row">
                <div class="column_base">
                  <div class="avg_pf">园所平均平衡评分</div>
                  <div
                    class="progressColor mt4"
                    :style="{
                      width: s_width4 + 'rem',
                      'border-color':
                        s_score4 < 60
                          ? '#2D8A00'
                          : s_score4 < 70
                          ? '#AD8600'
                          : s_score4 < 80
                          ? '#009C80'
                          : '#AD8600',
                      background:
                        s_score4 < 60
                          ? '#47D800'
                          : s_score4 < 70
                          ? '#F0BA00'
                          : s_score4 < 80
                          ? '#00E2BA'
                          : '#FE0101',
                    }"
                  ></div>
                </div>
                <div class="fs">{{ s_score4 }}分</div>
              </div>
              <img
                v-if="s_score4 <= 60"
                class="progress_arrow"
                :style="{ 'margin-left': s_width4 - 0.05 + 'rem' }"
                src="../../assets/img/home/home/PES/Icon_Arrow_G.png"
              />
              <img
                v-else-if="s_score4 <= 80"
                class="progress_arrow"
                :style="{ 'margin-left': s_width4 - 0.05 + 'rem' }"
                src="../../assets/img/home/home/PES/Icon_Arrow_Y.png"
              />
              <img
                v-else-if="s_score4 <= 90"
                class="progress_arrow"
                :style="{ 'margin-left': s_width4 - 0.05 + 'rem' }"
                src="../../assets/img/home/home/PES/Icon_Arrow_B.png"
              />
              <img
                v-else
                class="progress_arrow"
                :style="{ 'margin-left': s_width4 - 0.05 + 'rem' }"
                src="../../assets/img/home/home/PES/Icon_Arrow_R.png"
              />
              <img
                class="progress_line"
                src="../../assets/img/home/home/PES/Pic_JDT_PJ.png"
              />
            </div>
            <div class="line_v mt17"></div>
            <div class="width213 column_base mt13">
              <div class="row center mrl5 max">
                <div class="text1 flex">最快速度：</div>
                <div class="text1">{{ sportData.balance.best.value }}'</div>
                <img
                  v-if="sportData.balance.best.isDown"
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                />
                <img
                  v-else
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                />
              </div>
              <div class="row center mrl5 max mt20">
                <div class="text1 flex">最慢速度：</div>
                <div class="text1">{{ sportData.balance.worst.value }}'</div>
                <img
                  v-if="sportData.balance.worst.isDown"
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                />
                <img
                  v-else
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                />
              </div>
              <div class="row center mrl5 max mt20">
                <div class="text1 flex">本期完成率：</div>
                <div class="text1">
                  {{ sportData.balance.completeRate.value }}%
                </div>
                <img
                  v-if="sportData.balance.completeRate.isDown"
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                />
                <img
                  v-else
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                />
              </div>
              <div class="row center mrl5 max mt20">
                <div class="text1 flex">本期增长率：</div>
                <div class="text1">{{ sportData.balance.riseRate.value }}%</div>
                <img
                  v-if="sportData.balance.riseRate.isDown"
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                />
                <img
                  v-else
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                />
              </div>
            </div>
            <div class="line_v mt17"></div>
            <div class="width263 row mt13">
              <div class="column_center mrl42">
                <div class="ys_pos">
                  <div
                    class="pro"
                    :style="{ height: s_ysHeight4 + 'rem' }"
                  ></div>
                  <div class="bg flex"></div>
                </div>
                <div class="ys_text">园所</div>
              </div>
              <div class="column_center mrl13">
                <div class="ys_pos">
                  <div
                    class="pro"
                    :style="{ height: s_qgHeight4 + 'rem' }"
                  ></div>
                  <div class="qg_bg flex"></div>
                </div>
                <div class="qg_text">全国</div>
              </div>
              <div class="column_end flex">
                <div v-if="sportData.balance.r1.isDown" class="status1">
                  {{ sportData.balance.r1.remark }}
                </div>
                <div v-else class="status2">
                  {{ sportData.balance.r1.remark }}
                </div>
                <div v-if="sportData.balance.r2.isDown" class="status1 mt19">
                  {{ sportData.balance.r2.remark }}
                </div>
                <div v-else class="status2 mt19">
                  {{ sportData.balance.r2.remark }}
                </div>
                <div v-if="sportData.balance.r3.isDown" class="status1 mt19">
                  {{ sportData.balance.r3.remark }}
                </div>
                <div v-else class="status2 mt19">
                  {{ sportData.balance.r3.remark }}
                </div>
                <div v-if="sportData.balance.r4.isDown" class="status1 mt19">
                  {{ sportData.balance.r4.remark }}
                </div>
                <div v-else class="status2 mt19">
                  {{ sportData.balance.r4.remark }}
                </div>
              </div>
            </div>
          </div>
          <div class="line mt4"></div>
          <div class="row">
            <img
              class="image1"
              src="../../assets/img/home/home/PES/PIc_XT.png"
            />
            <div class="column_base flex">
              <div class="row mt12 width586">
                <div class="flag_text">协调</div>
                <div class="flex"></div>
                <div v-if="sportData.coordinate" class="avg_text">
                  平均协调：{{ sportData.coordinate.avgValue }}'
                </div>
              </div>
              <div class="row">
                <div class="column_base">
                  <div class="avg_pf">园所平均协调评分</div>
                  <div
                    class="progressColor mt4"
                    :style="{
                      width: s_width5 + 'rem',
                      'border-color':
                        s_score5 < 60
                          ? '#2D8A00'
                          : s_score5 < 70
                          ? '#AD8600'
                          : s_score5 < 80
                          ? '#009C80'
                          : '#AD8600',
                      background:
                        s_score5 < 60
                          ? '#47D800'
                          : s_score5 < 70
                          ? '#F0BA00'
                          : s_score5 < 80
                          ? '#00E2BA'
                          : '#FE0101',
                    }"
                  ></div>
                </div>
                <div class="fs">{{ s_score5 }}分</div>
              </div>
              <img
                v-if="s_score5 <= 60"
                class="progress_arrow"
                :style="{ 'margin-left': s_width5 - 0.05 + 'rem' }"
                src="../../assets/img/home/home/PES/Icon_Arrow_G.png"
              />
              <img
                v-else-if="s_score5 <= 80"
                class="progress_arrow"
                :style="{ 'margin-left': s_width5 - 0.05 + 'rem' }"
                src="../../assets/img/home/home/PES/Icon_Arrow_Y.png"
              />
              <img
                v-else-if="s_score5 <= 90"
                class="progress_arrow"
                :style="{ 'margin-left': s_width5 - 0.05 + 'rem' }"
                src="../../assets/img/home/home/PES/Icon_Arrow_B.png"
              />
              <img
                v-else
                class="progress_arrow"
                :style="{ 'margin-left': s_width5 - 0.05 + 'rem' }"
                src="../../assets/img/home/home/PES/Icon_Arrow_R.png"
              />
              <img
                class="progress_line"
                src="../../assets/img/home/home/PES/Pic_JDT_PJ.png"
              />
            </div>
            <div class="line_v mt17"></div>
            <div class="width213 column_base mt13">
              <div class="row center mrl5 max">
                <div class="text1 flex">最快速度：</div>
                <div class="text1">{{ sportData.coordinate.best.value }}'</div>
                <img
                  v-if="sportData.coordinate.best.isDown"
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                />
                <img
                  v-else
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                />
              </div>
              <div class="row center mrl5 max mt20">
                <div class="text1 flex">最慢速度：</div>
                <div class="text1">{{ sportData.coordinate.worst.value }}'</div>
                <img
                  v-if="sportData.coordinate.best.isDown"
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                />
                <img
                  v-else
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                />
              </div>
              <div class="row center mrl5 max mt20">
                <div class="text1 flex">本期完成率：</div>
                <div class="text1">
                  {{ sportData.coordinate.completeRate.value }}%
                </div>
                <img
                  v-if="sportData.coordinate.completeRate.isDown"
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                />
                <img
                  v-else
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                />
              </div>
              <div class="row center mrl5 max mt20">
                <div class="text1 flex">本期增长率：</div>
                <div class="text1">
                  {{ sportData.coordinate.riseRate.value }}%
                </div>
                <img
                  v-if="sportData.coordinate.riseRate.isDown"
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                />
                <img
                  v-else
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                />
              </div>
            </div>
            <div class="line_v mt17"></div>
            <div class="width263 row mt13">
              <div class="column_center mrl42">
                <div class="ys_pos">
                  <div
                    class="pro"
                    :style="{ height: s_ysHeight5 + 'rem' }"
                  ></div>
                  <div class="bg flex"></div>
                </div>
                <div class="ys_text">园所</div>
              </div>
              <div class="column_center mrl13">
                <div class="ys_pos">
                  <div
                    class="pro"
                    :style="{ height: s_qgHeight5 + 'rem' }"
                  ></div>
                  <div class="qg_bg flex"></div>
                </div>
                <div class="qg_text">全国</div>
              </div>
              <div class="column_end flex">
                <div v-if="sportData.coordinate.r1.isDown" class="status1">
                  {{ sportData.coordinate.r1.remark }}
                </div>
                <div v-else class="status2">
                  {{ sportData.coordinate.r1.remark }}
                </div>
                <div v-if="sportData.coordinate.r2.isDown" class="status1 mt19">
                  {{ sportData.coordinate.r2.remark }}
                </div>
                <div v-else class="status2 mt19">
                  {{ sportData.coordinate.r2.remark }}
                </div>
                <div v-if="sportData.coordinate.r3.isDown" class="status1 mt19">
                  {{ sportData.coordinate.r3.remark }}
                </div>
                <div v-else class="status2 mt19">
                  {{ sportData.coordinate.r3.remark }}
                </div>
                <div v-if="sportData.coordinate.r4.isDown" class="status1 mt19">
                  {{ sportData.coordinate.r4.remark }}
                </div>
                <div v-else class="status2 mt19">
                  {{ sportData.coordinate.r4.remark }}
                </div>
              </div>
            </div>
          </div>
          <div class="line mt4"></div>
          <div class="row">
            <img
              class="image1"
              src="../../assets/img/home/home/PES/Pic_LL.png"
            />
            <div class="column_base flex">
              <div class="row mt12 width586">
                <div class="flag_text">力量</div>
                <div class="flex"></div>
                <div v-if="sportData.power" class="avg_text">
                  平均力量：{{ sportData.power.avgValue }}'
                </div>
              </div>
              <div class="row">
                <div class="column_base">
                  <div class="avg_pf">园所平均力量评分</div>
                  <div
                    class="progressColor mt4"
                    :style="{
                      width: s_width6 + 'rem',
                      'border-color':
                        s_score6 < 60
                          ? '#2D8A00'
                          : s_score6 < 70
                          ? '#AD8600'
                          : s_score6 < 80
                          ? '#009C80'
                          : '#AD8600',
                      background:
                        s_score6 < 60
                          ? '#47D800'
                          : s_score6 < 70
                          ? '#F0BA00'
                          : s_score6 < 80
                          ? '#00E2BA'
                          : '#FE0101',
                    }"
                  ></div>
                </div>
                <div class="fs">{{ s_score6 }}分</div>
              </div>
              <img
                v-if="s_score6 <= 60"
                class="progress_arrow"
                :style="{ 'margin-left': s_width6 - 0.05 + 'rem' }"
                src="../../assets/img/home/home/PES/Icon_Arrow_G.png"
              />
              <img
                v-else-if="s_score6 <= 80"
                class="progress_arrow"
                :style="{ 'margin-left': s_width6 - 0.05 + 'rem' }"
                src="../../assets/img/home/home/PES/Icon_Arrow_Y.png"
              />
              <img
                v-else-if="s_score6 <= 90"
                class="progress_arrow"
                :style="{ 'margin-left': s_width6 - 0.05 + 'rem' }"
                src="../../assets/img/home/home/PES/Icon_Arrow_B.png"
              />
              <img
                v-else
                class="progress_arrow"
                :style="{ 'margin-left': s_width6 - 0.05 + 'rem' }"
                src="../../assets/img/home/home/PES/Icon_Arrow_R.png"
              />
              <img
                class="progress_line"
                src="../../assets/img/home/home/PES/Pic_JDT_PJ.png"
              />
            </div>
            <div class="line_v mt17"></div>
            <div class="width213 column_base mt13">
              <div class="row center mrl5 max">
                <div class="text1 flex">最快速度：</div>
                <div class="text1">{{ sportData.power.best.value }}'</div>
                <img
                  v-if="sportData.power.best.isDown"
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                />
                <img
                  v-else
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                />
              </div>
              <div class="row center mrl5 max mt20">
                <div class="text1 flex">最慢速度：</div>
                <div class="text1">{{ sportData.power.worst.value }}'</div>
                <img
                  v-if="sportData.power.worst.isDown"
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                />
                <img
                  v-else
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                />
              </div>
              <div class="row center mrl5 max mt20">
                <div class="text1 flex">本期完成率：</div>
                <div class="text1">
                  {{ sportData.power.completeRate.value }}%
                </div>
                <img
                  v-if="sportData.power.completeRate.isDown"
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                />
                <img
                  v-else
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                />
              </div>
              <div class="row center mrl5 max mt20">
                <div class="text1 flex">本期增长率：</div>
                <div class="text1">{{ sportData.power.riseRate.value }}%</div>
                <img
                  v-if="sportData.power.riseRate.isDown"
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                />
                <img
                  v-else
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                />
              </div>
            </div>
            <div class="line_v mt17"></div>
            <div class="width263 row mt13">
              <div class="column_center mrl42">
                <div class="ys_pos">
                  <div
                    class="pro"
                    :style="{ height: s_ysHeight6 + 'rem' }"
                  ></div>
                  <div class="bg flex"></div>
                </div>
                <div class="ys_text">园所</div>
              </div>
              <div class="column_center mrl13">
                <div class="ys_pos">
                  <div
                    class="pro"
                    :style="{ height: s_qgHeight6 + 'rem' }"
                  ></div>
                  <div class="qg_bg flex"></div>
                </div>
                <div class="qg_text">全国</div>
              </div>
              <div class="column_end flex">
                <div v-if="sportData.power.r1.isDown" class="status1">
                  {{ sportData.power.r1.remark }}
                </div>
                <div v-else class="status2">
                  {{ sportData.power.r1.remark }}
                </div>
                <div v-if="sportData.power.r2.isDown" class="status1 mt19">
                  {{ sportData.power.r2.remark }}
                </div>
                <div v-else class="status2 mt19">
                  {{ sportData.power.r2.remark }}
                </div>
                <div v-if="sportData.power.r3.isDown" class="status1 mt19">
                  {{ sportData.power.r3.remark }}
                </div>
                <div v-else class="status2 mt19">
                  {{ sportData.power.r3.remark }}
                </div>
                <div v-if="sportData.power.r4.isDown" class="status1 mt19">
                  {{ sportData.power.r4.remark }}
                </div>
                <div v-else class="status2 mt19">
                  {{ sportData.power.r4.remark }}
                </div>
              </div>
            </div>
          </div>
          <div class="line mt4"></div>
          <div class="row">
            <img
              class="image1"
              src="../../assets/img/home/home/PES/Pic_Hand.png"
            />
            <div class="column_base flex">
              <div class="row mt12 width586">
                <div class="flag_text">物体控制手</div>
                <div class="flex"></div>
                <div v-if="sportData.ctrlHand" class="avg_text">
                  平均物体控制手：{{ sportData.ctrlHand.avgValue }}'
                </div>
              </div>
              <div class="row">
                <div class="column_base">
                  <div class="avg_pf">园所平均物体控制手评分</div>
                  <div
                    class="progressColor mt4"
                    :style="{
                      width: s_width7 + 'rem',
                      'border-color':
                        s_score7 < 60
                          ? '#2D8A00'
                          : s_score7 < 70
                          ? '#AD8600'
                          : s_score7 < 80
                          ? '#009C80'
                          : '#AD8600',
                      background:
                        s_score7 < 60
                          ? '#47D800'
                          : s_score7 < 70
                          ? '#F0BA00'
                          : s_score7 < 80
                          ? '#00E2BA'
                          : '#FE0101',
                    }"
                  ></div>
                </div>
                <div class="fs">{{ s_score7 }}分</div>
              </div>
              <img
                v-if="s_score7 <= 60"
                class="progress_arrow"
                :style="{ 'margin-left': s_width7 - 0.05 + 'rem' }"
                src="../../assets/img/home/home/PES/Icon_Arrow_G.png"
              />
              <img
                v-else-if="s_score7 <= 80"
                class="progress_arrow"
                :style="{ 'margin-left': s_width7 - 0.05 + 'rem' }"
                src="../../assets/img/home/home/PES/Icon_Arrow_Y.png"
              />
              <img
                v-else-if="s_score7 <= 90"
                class="progress_arrow"
                :style="{ 'margin-left': s_width7 - 0.05 + 'rem' }"
                src="../../assets/img/home/home/PES/Icon_Arrow_B.png"
              />
              <img
                v-else
                class="progress_arrow"
                :style="{ 'margin-left': s_width7 - 0.05 + 'rem' }"
                src="../../assets/img/home/home/PES/Icon_Arrow_R.png"
              />
              <img
                class="progress_line"
                src="../../assets/img/home/home/PES/Pic_JDT_PJ.png"
              />
            </div>
            <div class="line_v mt17"></div>
            <div class="width213 column_base mt13">
              <div class="row center mrl5 max">
                <div class="text1 flex">最快速度：</div>
                <div class="text1">{{ sportData.ctrlHand.best.value }}'</div>
                <img
                  v-if="sportData.ctrlHand.best.isDown"
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                />
                <img
                  v-else
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                />
              </div>
              <div class="row center mrl5 max mt20">
                <div class="text1 flex">最慢速度：</div>
                <div class="text1">{{ sportData.ctrlHand.worst.value }}'</div>
                <img
                  v-if="sportData.ctrlHand.worst.isDown"
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                />
                <img
                  v-else
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                />
              </div>
              <div class="row center mrl5 max mt20">
                <div class="text1 flex">本期完成率：</div>
                <div class="text1">
                  {{ sportData.ctrlHand.completeRate.value }}%
                </div>
                <img
                  v-if="sportData.ctrlHand.completeRate.isDown"
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                />
                <img
                  v-else
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                />
              </div>
              <div class="row center mrl5 max mt20">
                <div class="text1 flex">本期增长率：</div>
                <div class="text1">
                  {{ sportData.ctrlHand.riseRate.value }}%
                </div>
                <img
                  v-if="sportData.ctrlHand.riseRate.isDown"
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                />
                <img
                  v-else
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                />
              </div>
            </div>
            <div class="line_v mt17"></div>
            <div class="width263 row mt13">
              <div class="column_center mrl42">
                <div class="ys_pos">
                  <div
                    class="pro"
                    :style="{ height: s_ysHeight7 + 'rem' }"
                  ></div>
                  <div class="bg flex"></div>
                </div>
                <div class="ys_text">园所</div>
              </div>
              <div class="column_center mrl13">
                <div class="ys_pos">
                  <div
                    class="pro"
                    :style="{ height: s_qgHeight7 + 'rem' }"
                  ></div>
                  <div class="qg_bg flex"></div>
                </div>
                <div class="qg_text">全国</div>
              </div>
              <div class="column_end flex">
                <div v-if="sportData.ctrlHand.r1.isDown" class="status1">
                  {{ sportData.ctrlHand.r1.remark }}
                </div>
                <div v-else class="status2">
                  {{ sportData.ctrlHand.r1.remark }}
                </div>
                <div v-if="sportData.ctrlHand.r2.isDown" class="status1 mt19">
                  {{ sportData.ctrlHand.r2.remark }}
                </div>
                <div v-else class="status2 mt19">
                  {{ sportData.ctrlHand.r2.remark }}
                </div>
                <div v-if="sportData.ctrlHand.r3.isDown" class="status1 mt19">
                  {{ sportData.ctrlHand.r3.remark }}
                </div>
                <div v-else class="status2 mt19">
                  {{ sportData.ctrlHand.r3.remark }}
                </div>
                <div v-if="sportData.ctrlHand.r4.isDown" class="status1 mt19">
                  {{ sportData.ctrlHand.r4.remark }}
                </div>
                <div v-else class="status2 mt19">
                  {{ sportData.ctrlHand.r4.remark }}
                </div>
              </div>
            </div>
          </div>
          <div class="line mt4"></div>
          <div class="row">
            <img
              class="image1"
              src="../../assets/img/home/home/PES/Pic_Foot.png"
            />
            <div class="column_base flex">
              <div class="row mt12 width586">
                <div class="flag_text">物体控制脚</div>
                <div class="flex"></div>
                <div v-if="sportData.ctrlFoot" class="avg_text">
                  平均物体控制脚：{{ sportData.ctrlFoot.avgValue }}'
                </div>
              </div>
              <div class="row">
                <div class="column_base">
                  <div class="avg_pf">园所平均物体控制脚评分</div>
                  <div
                    class="progressColor mt4"
                    :style="{
                      width: s_width8 + 'rem',
                      'border-color':
                        s_score8 < 60
                          ? '#2D8A00'
                          : s_score8 < 70
                          ? '#AD8600'
                          : s_score8 < 80
                          ? '#009C80'
                          : '#AD8600',
                      background:
                        s_score8 < 60
                          ? '#47D800'
                          : s_score8 < 70
                          ? '#F0BA00'
                          : s_score8 < 80
                          ? '#00E2BA'
                          : '#FE0101',
                    }"
                  ></div>
                </div>
                <div class="fs">{{ s_score8 }}分</div>
              </div>
              <img
                v-if="s_score8 <= 60"
                class="progress_arrow"
                :style="{ 'margin-left': s_width8 - 0.05 + 'rem' }"
                src="../../assets/img/home/home/PES/Icon_Arrow_G.png"
              />
              <img
                v-else-if="s_score8 <= 80"
                class="progress_arrow"
                :style="{ 'margin-left': s_width8 - 0.05 + 'rem' }"
                src="../../assets/img/home/home/PES/Icon_Arrow_Y.png"
              />
              <img
                v-else-if="s_score8 <= 90"
                class="progress_arrow"
                :style="{ 'margin-left': s_width8 - 0.05 + 'rem' }"
                src="../../assets/img/home/home/PES/Icon_Arrow_B.png"
              />
              <img
                v-else
                class="progress_arrow"
                :style="{ 'margin-left': s_width8 - 0.05 + 'rem' }"
                src="../../assets/img/home/home/PES/Icon_Arrow_R.png"
              />
              <img
                class="progress_line"
                src="../../assets/img/home/home/PES/Pic_JDT_PJ.png"
              />
            </div>
            <div class="line_v mt17"></div>
            <div class="width213 column_base mt13">
              <div class="row center mrl5 max">
                <div class="text1 flex">最快速度：</div>
                <div class="text1">{{ sportData.ctrlFoot.best.value }}'</div>
                <img
                  v-if="sportData.ctrlFoot.best.isDown"
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                />
                <img
                  v-else
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                />
              </div>
              <div class="row center mrl5 max mt20">
                <div class="text1 flex">最慢速度：</div>
                <div class="text1">{{ sportData.ctrlFoot.worst.value }}'</div>
                <img
                  v-if="sportData.ctrlFoot.worst.isDown"
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                />
                <img
                  v-else
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                />
              </div>
              <div class="row center mrl5 max mt20">
                <div class="text1 flex">本期完成率：</div>
                <div class="text1">
                  {{ sportData.ctrlFoot.completeRate.value }}%
                </div>
                <img
                  v-if="sportData.ctrlFoot.completeRate.isDown"
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                />
                <img
                  v-else
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                />
              </div>
              <div class="row center mrl5 max mt20">
                <div class="text1 flex">本期增长率：</div>
                <div class="text1">
                  {{ sportData.ctrlFoot.riseRate.value }}%
                </div>
                <img
                  v-if="sportData.ctrlFoot.riseRate.isDown"
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Down.png"
                />
                <img
                  v-else
                  class="arrow_v"
                  src="../../assets/img/home/home/PES/Icon_Arrow_Up.png"
                />
              </div>
            </div>
            <div class="line_v mt17"></div>
            <div class="width263 row mt13">
              <div class="column_center mrl42">
                <div class="ys_pos">
                  <div
                    class="pro"
                    :style="{ height: s_ysHeight8 + 'rem' }"
                  ></div>
                  <div class="bg flex"></div>
                </div>
                <div class="ys_text">园所</div>
              </div>
              <div class="column_center mrl13">
                <div class="ys_pos">
                  <div
                    class="pro"
                    :style="{ height: s_qgHeight8 + 'rem' }"
                  ></div>
                  <div class="qg_bg flex"></div>
                </div>
                <div class="qg_text">全国</div>
              </div>
              <div class="column_end flex">
                <div v-if="sportData.ctrlFoot.r1.isDown" class="status1">
                  {{ sportData.ctrlFoot.r1.remark }}
                </div>
                <div v-else class="status2">
                  {{ sportData.ctrlFoot.r1.remark }}
                </div>
                <div v-if="sportData.ctrlFoot.r2.isDown" class="status1 mt19">
                  {{ sportData.ctrlFoot.r2.remark }}
                </div>
                <div v-else class="status2 mt19">
                  {{ sportData.ctrlFoot.r2.remark }}
                </div>
                <div v-if="sportData.ctrlFoot.r3.isDown" class="status1 mt19">
                  {{ sportData.ctrlFoot.r3.remark }}
                </div>
                <div v-else class="status2 mt19">
                  {{ sportData.ctrlFoot.r3.remark }}
                </div>
                <div v-if="sportData.ctrlFoot.r4.isDown" class="status1 mt19">
                  {{ sportData.ctrlFoot.r4.remark }}
                </div>
                <div v-else class="status2 mt19">
                  {{ sportData.ctrlFoot.r4.remark }}
                </div>
              </div>
            </div>
          </div>
          <div class="line mt4"></div>

          </div>
          
          <div class="top_lan mt137">
            <div class="title4">综合报告</div>
          </div>
          <div class="line mt33"></div>
          <div class="bg_row widthMax h300">
            <div class="column_center">
              <div class="pre">上<br /><br />期</div>
              <div class="next">下<br /><br />期</div>
            </div>
            <div id="ydBarChart" class="barChart"></div>
            <div class="flex"></div>
            <div class="column_base width624">
              <div class="fx">同期对比分析：</div>
              <div class="row center mt13">
                <div class="color1"></div>
                <div class="value">空值分析：{{ chartValue.nullRemark }}</div>
              </div>
              <div class="row center mt5">
                <div class="color2"></div>
                <div class="value">较差分析：{{ chartValue.badRemark }}</div>
              </div>
              <div class="row center mt5">
                <div class="color3"></div>
                <div class="value">优势分析：{{ chartValue.fineRemark }}</div>
              </div>
              <div class="row center mt5">
                <div class="color4"></div>
                <div class="value">整体分析：{{ chartValue.allRemark }}</div>
              </div>
            </div>
          </div>
          <div class="line"></div>
          <div class="bg_row widthMax h300">
            <div class="column_center">
              <div class="pre">全<br /><br />国</div>
              <div class="next">本<br />园<br />所</div>
            </div>
            <div class="row mrl20">
              <div class="column_center">
                <div class="row">
                  <div class="left_pos">
                    <div class="left_bg"></div>
                    <div class="column_base h180 abs">
                      <div class="flex"></div>
                      <div class="left_value">{{ s_fxysValue1 }}s</div>
                      <div class="left_y"></div>
                      <div
                        class="left_v"
                        :style="{ height: s_fxysHeight1 + 'rem' }"
                      ></div>
                    </div>
                  </div>
                  <div class="left_pos mrl34">
                    <div class="left_bg"></div>
                    <div class="column_base h180 abs">
                      <div class="flex"></div>
                      <div class="right_value">{{ s_fxqgValue1 }}s</div>
                      <div class="right_y"></div>
                      <div
                        class="right_v"
                        :style="{ height: s_fxqgHeight1 + 'rem' }"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="circle_left"></div>
                  <div class="circle_right"></div>
                </div>
                <div class="bot_text">跑动</div>
              </div>
              <div class="column_center mrl30">
                <div class="row">
                  <div class="left_pos">
                    <div class="left_bg"></div>
                    <div class="column_base h180 abs">
                      <div class="flex"></div>
                      <div class="left_value">{{ s_fxysValue2 }}个</div>
                      <div class="left_y"></div>
                      <div
                        class="left_v"
                        :style="{ height: s_fxysHeight2 + 'rem' }"
                      ></div>
                    </div>
                  </div>
                  <div class="left_pos mrl34">
                    <div class="left_bg"></div>
                    <div class="column_base h180 abs">
                      <div class="flex"></div>
                      <div class="right_value">{{ s_fxqgValue2 }}个</div>
                      <div class="right_y"></div>
                      <div
                        class="right_v"
                        :style="{ height: s_fxqgHeight2 + 'rem' }"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="circle_left"></div>
                  <div class="circle_right"></div>
                </div>
                <div class="bot_text">跳跃</div>
              </div>
              <div class="column_center mrl20">
                <div class="row">
                  <div class="left_pos">
                    <div class="left_bg"></div>
                    <div class="column_base h180 abs">
                      <div class="flex"></div>
                      <div class="left_value">{{ s_fxysValue3 }}s</div>
                      <div class="left_y"></div>
                      <div
                        class="left_v"
                        :style="{ height: s_fxysHeight3 + 'rem' }"
                      ></div>
                    </div>
                  </div>
                  <div class="left_pos mrl34">
                    <div class="left_bg"></div>
                    <div class="column_base h180 abs">
                      <div class="flex"></div>
                      <div class="right_value">{{ s_fxqgValue3 }}s</div>
                      <div class="right_y"></div>
                      <div
                        class="right_v"
                        :style="{ height: s_fxqgHeight3 + 'rem' }"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="circle_left"></div>
                  <div class="circle_right"></div>
                </div>
                <div class="bot_text">敏捷</div>
              </div>
              <div class="column_center mrl20">
                <div class="row">
                  <div class="left_pos">
                    <div class="left_bg"></div>
                    <div class="column_base h180 abs">
                      <div class="flex"></div>
                      <div class="left_value">{{ s_fxysValue4 }}s</div>
                      <div class="left_y"></div>
                      <div
                        class="left_v"
                        :style="{ height: s_fxysHeight4 + 'rem' }"
                      ></div>
                    </div>
                  </div>
                  <div class="left_pos mrl34">
                    <div class="left_bg"></div>
                    <div class="column_base h180 abs">
                      <div class="flex"></div>
                      <div class="right_value">{{ s_fxqgValue4 }}s</div>
                      <div class="right_y"></div>
                      <div
                        class="right_v"
                        :style="{ height: s_fxqgHeight4 + 'rem' }"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="circle_left"></div>
                  <div class="circle_right"></div>
                </div>
                <div class="bot_text">平衡</div>
              </div>
              <div class="column_center mrl20">
                <div class="row">
                  <div class="left_pos">
                    <div class="left_bg"></div>
                    <div class="column_base h180 abs">
                      <div class="flex"></div>
                      <div class="left_value">{{ s_fxysValue5 }}s</div>
                      <div class="left_y"></div>
                      <div
                        class="left_v"
                        :style="{ height: s_fxysHeight5 + 'rem' }"
                      ></div>
                    </div>
                  </div>
                  <div class="left_pos mrl34">
                    <div class="left_bg"></div>
                    <div class="column_base h180 abs">
                      <div class="flex"></div>
                      <div class="right_value">{{ s_fxqgValue5 }}s</div>
                      <div class="right_y"></div>
                      <div
                        class="right_v"
                        :style="{ height: s_fxqgHeight5 + 'rem' }"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="circle_left"></div>
                  <div class="circle_right"></div>
                </div>
                <div class="bot_text">协调</div>
              </div>
              <div class="column_center mrl20">
                <div class="row">
                  <div class="left_pos">
                    <div class="left_bg"></div>
                    <div class="column_base h180 abs">
                      <div class="flex"></div>
                      <div class="left_value">{{ s_fxysValue6 }}s</div>
                      <div class="left_y"></div>
                      <div
                        class="left_v"
                        :style="{ height: s_fxysHeight6 + 'rem' }"
                      ></div>
                    </div>
                  </div>
                  <div class="left_pos mrl34">
                    <div class="left_bg"></div>
                    <div class="column_base h180 abs">
                      <div class="flex"></div>
                      <div class="right_value">{{ s_fxqgValue6 }}s</div>
                      <div class="right_y"></div>
                      <div
                        class="right_v"
                        :style="{ height: s_fxqgHeight6 + 'rem' }"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="circle_left"></div>
                  <div class="circle_right"></div>
                </div>
                <div class="bot_text">力量</div>
              </div>
              <div class="column_center mrl20">
                <div class="row">
                  <div class="left_pos">
                    <div class="left_bg"></div>
                    <div class="column_base h180 abs">
                      <div class="flex"></div>
                      <div class="left_value">{{ s_fxysValue7 }}个</div>
                      <div class="left_y"></div>
                      <div
                        class="left_v"
                        :style="{ height: s_fxysHeight7 + 'rem' }"
                      ></div>
                    </div>
                  </div>
                  <div class="left_pos mrl34">
                    <div class="left_bg"></div>
                    <div class="column_base h180 abs">
                      <div class="flex"></div>
                      <div class="right_value">{{ s_fxqgValue7 }}个</div>
                      <div class="right_y"></div>
                      <div
                        class="right_v"
                        :style="{ height: s_fxqgHeight7 + 'rem' }"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="circle_left"></div>
                  <div class="circle_right"></div>
                </div>
                <div class="bot_text">物控手</div>
              </div>
              <div class="column_center mrl20">
                <div class="row">
                  <div class="left_pos">
                    <div class="left_bg"></div>
                    <div class="column_base h180 abs">
                      <div class="flex"></div>
                      <div class="left_value">{{ s_fxysValue8 }}s</div>
                      <div class="left_y"></div>
                      <div
                        class="left_v"
                        :style="{ height: s_fxysHeight8 + 'rem' }"
                      ></div>
                    </div>
                  </div>
                  <div class="left_pos mrl34">
                    <div class="left_bg"></div>
                    <div class="column_base h180 abs">
                      <div class="flex"></div>
                      <div class="right_value">{{ s_fxqgValue8 }}s</div>
                      <div class="right_y"></div>
                      <div
                        class="right_v"
                        :style="{ height: s_fxqgHeight8 + 'rem' }"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="circle_left"></div>
                  <div class="circle_right"></div>
                </div>
                <div class="bot_text">物控脚</div>
              </div>
            </div>
            <div class="flex"></div>
            <div class="column_base width624">
              <div class="fx">平均值全国分析：</div>
              <div class="row center mt13">
                <div class="color1"></div>
                <div class="value">空值分析：{{ chartValue.nullRemark }}</div>
              </div>
              <div class="row center mt5">
                <div class="color2"></div>
                <div class="value">较差分析：{{ chartValue.badRemark }}</div>
              </div>
              <div class="row center mt5">
                <div class="color3"></div>
                <div class="value">优势分析：{{ chartValue.fineRemark }}</div>
              </div>
              <div class="row center mt5">
                <div class="color4"></div>
                <div class="value">整体分析：{{ chartValue.allRemark }}</div>
              </div>
            </div>
          </div>
          <div class="line"></div>
          <div class="bg_row widthMax h300">
            <div class="column_center">
              <div class="pre">全<br /><br />国</div>
              <div class="next">本<br />园<br />所</div>
            </div>
            <div class="column_base mrl49">
              <div id="ydLineChart" class="tzLineChart"></div>
              <div class="center width504 row">
                <div class="flex center">
                  <div class="bot_text">{{ xData2[0] }}</div>
                </div>
                <div class="flex center">
                  <div class="bot_text">{{ xData2[1] }}</div>
                </div>
                <div class="flex center">
                  <div class="bot_text">{{ xData2[2] }}</div>
                </div>
                <div class="flex center">
                  <div class="bot_text">{{ xData2[3] }}</div>
                </div>
                <div class="flex center">
                  <div class="bot_text">{{ xData2[4] }}</div>
                </div>
                <div class="flex center">
                  <div class="bot_text">{{ xData2[5] }}</div>
                </div>
                <div class="flex center">
                  <div class="bot_text">{{ xData2[6] }}</div>
                </div>
                <div class="flex center">
                  <div class="bot_text">{{ xData2[7] }}</div>
                </div>
              </div>
            </div>
            <div class="flex"></div>
            <div class="column_base width624">
              <div class="fx">增长率全国对比：</div>
              <div class="row center mt13">
                <div class="color1"></div>
                <div class="value">空值分析：{{ chartValue.nullRemark }}</div>
              </div>
              <div class="row center mt5">
                <div class="color2"></div>
                <div class="value">较差分析：{{ chartValue.badRemark }}</div>
              </div>
              <div class="row center mt5">
                <div class="color3"></div>
                <div class="value">优势分析：{{ chartValue.fineRemark }}</div>
              </div>
              <div class="row center mt5">
                <div class="color4"></div>
                <div class="value">整体分析：{{ chartValue.allRemark }}</div>
              </div>
            </div>
          </div>
          <div class="line"></div>
          <div class="bg_row widthMax h300">
            <div class="column_center">
              <div class="abc1">需努力</div>
              <div class="abc2">合格</div>
              <div class="abc3">良好</div>
              <div class="abc4">优秀</div>
            </div>
            <div class="column_base">
              <div id="ydYbChart" class="tzYbChart"></div>
            </div>
            <div class="flex"></div>
            <div class="column_base width624">
              <div class="fx">优秀率对比：</div>
              <div class="row center mt13">
                <div class="color1"></div>
                <div class="value">空值分析：{{ chartValue.nullRemark }}</div>
              </div>
              <div class="row center mt5">
                <div class="color2"></div>
                <div class="value">较差分析：{{ chartValue.badRemark }}</div>
              </div>
              <div class="row center mt5">
                <div class="color3"></div>
                <div class="value">优势分析：{{ chartValue.fineRemark }}</div>
              </div>
              <div class="row center mt5">
                <div class="color4"></div>
                <div class="value">整体分析：{{ chartValue.allRemark }}</div>
              </div>
            </div>
          </div>
          <div class="line"></div>
        </div>
      </div>
    <div class="h240"></div>
  </div>
</div>
</template>
<script>
import * as echarts from "echarts";
import domtoimage from "dom-to-image";
export default {
  name: "gradeZHFX",
  components: {},
  data() {
    return {
      charts: null,
      monthList: [],
      monthType: "",
      num1: 0,
      num2: 0,
      num3: 0,
      sportData: {},
      tzData: {},
      operateType: 1,
      tzBarChart: null,
      loading: false,
      chartValue: {},
      indicator1: [
        {
          name: "身高\nCM",
          max: 150,
        },
        { name: "体重\nKG", max: 45 },
        { name: "视力", max: 5.4 },
        { name: "臂展\nCM", max: 180 },
        { name: "肺活量\nML", max: 2000 },
        { name: "胸围\nCM", max: 110 },
        { name: "体前屈\nCM", max: 50 },
      ],
      indicator2: [
        { name: "跑动\nS", max: 60 },
        { name: "跳跃\n个", max: 60 },
        { name: "敏捷\nS", max: 90 },
        { name: "力量\nS", max: 120 },
        { name: "协调\nS", max: 90 },
        { name: "平衡\nS", max: 90 },
        { name: "物控手\n个", max: 10 },
        { name: "物控脚\n个", max: 10 },
      ],
      preValue: [],
      nextValue: [],
      fxysHeight1: 0,
      fxysHeight2: 0,
      fxysHeight3: 0,
      fxysHeight4: 0,
      fxysHeight5: 0,
      fxysHeight6: 0,
      fxysHeight7: 0,
      fxysValue1: 0,
      fxysValue2: 0,
      fxysValue3: 0,
      fxysValue4: 0,
      fxysValue5: 0,
      fxysValue6: 0,
      fxysValue7: 0,
      fxqgHeight1: 0,
      fxqgHeight2: 0,
      fxqgHeight3: 0,
      fxqgHeight4: 0,
      fxqgHeight5: 0,
      fxqgHeight6: 0,
      fxqgHeight7: 0,
      fxqgValue1: 0,
      fxqgValue2: 0,
      fxqgValue3: 0,
      fxqgValue4: 0,
      fxqgValue5: 0,
      fxqgValue6: 0,
      fxqgValue7: 0,
      s_fxysHeight1: 0,
      s_fxysHeight2: 0,
      s_fxysHeight3: 0,
      s_fxysHeight4: 0,
      s_fxysHeight5: 0,
      s_fxysHeight6: 0,
      s_fxysHeight7: 0,
      s_fxysHeight8: 0,
      s_fxysValue1: 0,
      s_fxysValue2: 0,
      s_fxysValue3: 0,
      s_fxysValue4: 0,
      s_fxysValue5: 0,
      s_fxysValue6: 0,
      s_fxysValue7: 0,
      s_fxysValue8: 0,
      s_fxqgHeight1: 0,
      s_fxqgHeight2: 0,
      s_fxqgHeight3: 0,
      s_fxqgHeight4: 0,
      s_fxqgHeight5: 0,
      s_fxqgHeight6: 0,
      s_fxqgHeight7: 0,
      s_fxqgHeight8: 0,
      s_fxqgValue1: 0,
      s_fxqgValue2: 0,
      s_fxqgValue3: 0,
      s_fxqgValue4: 0,
      s_fxqgValue5: 0,
      s_fxqgValue6: 0,
      s_fxqgValue7: 0,
      s_fxqgValue8: 0,
      tzLineChart: null,
      width1: 0,
      width2: 0,
      width3: 0,
      width4: 0,
      width5: 0,
      width6: 0,
      width7: 0,
      score1: 0,
      score2: 0,
      score3: 0,
      score4: 0,
      score5: 0,
      score6: 0,
      score7: 0,
      s_width1: 0,
      s_width2: 0,
      s_width3: 0,
      s_width4: 0,
      s_width5: 0,
      s_width6: 0,
      s_width7: 0,
      s_score1: 0,
      s_score2: 0,
      s_score3: 0,
      s_score4: 0,
      s_score5: 0,
      s_score6: 0,
      s_score7: 0,
      xData1: ["身高", "体重", "视力", "臂展", "肺活量", "胸围", "体前屈"],
      xData2: [
        "跑动",
        "跳跃",
        "敏捷",
        "平衡",
        "协调",
        "力量",
        "物控手",
        "物控脚",
      ],
      qgLineData: [],
      ysLineData: [],
      grid1: { top: "10px", left: "17px", right: "55px", bottom: "0px" },
      grid2: { top: "10px", left: "17px", right: "40px", bottom: "0px" },
      tzYbChart: null,
      ybData: [],
      ydBarChart: null,
      ydLineChart: null,
      ydYbChart: null,
      currentGradeId: "",
      flag: 0,
      gradeName: "",
      classList: [],
    };
  },

  methods: {
    clickMonth(item) {
      this.monthType = item.value;
      this.monthValue = item.name;
      this.getTableData();
      if (this.operateType == 1) {
        this.getTzData();
        this.getBg();
      } else {
        this.getSportData();
        this.getBg();
      }
    },
    change(e) {
      this.currentGradeId = e;
      this.getTableData();
      if (this.operateType == 1) {
        this.getTzData();
        this.getBg();
      } else {
        this.getSportData();
        this.getBg();
      }
    },
    clickOperateType(index) {
      this.operateType = index;
      if (this.operateType == 1) {
        this.getTzData();
        this.getBg();
      } else {
        this.getSportData();
        this.getBg();
      }
    },
    downLoad() {
      this.loading = true;
      this.shotPic("download");
    },
    formatWidth(score) {
      if (score >= 90) {
        return 5.3;
      }
      return (score / 100) * 5.86;
    },
    formatHeight(score) {
      return 1.34 - (score / 100) * 1.34;
    },
    formatFxHeight(score) {
      if (score >= 90) {
        return 1.58;
      }
      return (score / 100) * 1.78;
    },
    drawBar(id) {
      if (this.tzBarChart == null) {
        this.tzBarChart = echarts.init(document.getElementById(id));
      }
      this.tzBarChart.setOption({
        radar: {
          shape: "circle",
          indicator: this.indicator1,
          center: ["50%", "50%"],
          radius: "60%",
          name: {
            color: "#172570",
          },
          splitNumber: 3,
          axisLine: {
            lineStyle: {
              color: "#D1D1D1",
            },
          },
          splitLine: {
            lineStyle: {
              color: "#D1D1D1",
            },
          },
          splitArea: {
            areaStyle: {
              color: "#e8e8e8",
            },
          },
        },
        series: [
          {
            name: "上期",
            type: "radar",
            data: [
              {
                value: this.preValue,
                name: "",
              },
            ],
            symbolSize: 16,
            itemStyle: {
              normal: {
                color: "#BC5050",
              },
            },
            label: {
              normal: {
                show: true,
                color: "#BC5050",
              },
            },
            lineStyle: {
              width: 2,
              color: "#BC5050",
              labelLine: {
                show: true, //隐藏标示线
              },
            },
          },
          {
            name: "下期",
            type: "radar",
            data: [
              {
                value: this.nextValue,
                name: "",
              },
            ],
            symbolSize: 16,
            itemStyle: {
              normal: {
                color: "#001166",
              },
            },
            label: {
              normal: {
                show: true,
                color: "#001166",
              },
            },
            lineStyle: {
              width: 2,
              color: "#001166",
              labelLine: {
                show: true, //隐藏标示线
              },
            },
          },
        ],
      });
    },
    drawYdBar(id) {
      if (this.ydBarChart == null) {
        this.ydBarChart = echarts.init(document.getElementById(id));
      }
      this.ydBarChart.setOption({
        radar: {
          shape: "circle",
          indicator: this.indicator2,
          center: ["50%", "50%"],
          radius: "60%",
          name: {
            color: "#172570",
          },
          splitNumber: 3,
          axisLine: {
            lineStyle: {
              color: "#D1D1D1",
            },
          },
          splitLine: {
            lineStyle: {
              color: "#D1D1D1",
            },
          },
          splitArea: {
            areaStyle: {
              color: "#e8e8e8",
            },
          },
        },
        series: [
          {
            name: "上期",
            type: "radar",
            data: [
              {
                value: this.preValue,
                name: "",
              },
            ],
            symbolSize: 16,
            itemStyle: {
              normal: {
                color: "#BC5050",
              },
            },
            label: {
              normal: {
                show: true,
                color: "#BC5050",
              },
            },
            lineStyle: {
              width: 2,
              color: "#BC5050",
              labelLine: {
                show: true, //隐藏标示线
              },
            },
          },
          {
            name: "下期",
            type: "radar",
            data: [
              {
                value: this.nextValue,
                name: "",
              },
            ],
            symbolSize: 16,
            itemStyle: {
              normal: {
                color: "#001166",
              },
            },
            label: {
              normal: {
                show: true,
                color: "#001166",
              },
            },
            lineStyle: {
              width: 2,
              color: "#001166",
              labelLine: {
                show: true, //隐藏标示线
              },
            },
          },
        ],
      });
    },
    // 条形图
    drawLine(id) {
      if (this.tzLineChart == null) {
        this.tzLineChart = echarts.init(document.getElementById(id));
      }
      this.tzLineChart.setOption({
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,0.7)", // 提示背景颜色，默认为透明度为0.7的黑色
          borderColor: "#333", // 提示边框颜色
          borderRadius: 4, // 提示边框圆角，单位px，默认为4
          borderWidth: 0, // 提示边框线宽，单位px，默认为0（无边框）
          formatter: function (datas) {
            console.log(datas);
            var res = datas[0].axisValue + "<br/>";
            for (var i = 0; i < datas.length; i++) {
              var val = datas[i].value;
              res += datas[i].seriesName + "：" + val + "%<br/>";
            }
            return res;
          },
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
            lineStyle: {
              // 直线指示器样式设置
              color: "#48b",
              width: 2,
              type: "solid",
            },
            shadowStyle: {
              // 阴影指示器样式设置
              width: "auto", // 阴影大小
              color: "rgba(0, 255, 198, 0.2)", // 阴影颜色
            },
          },
          textStyle: {
            color: "#fff",
          },
        },
        grid: this.grid1,
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.xData1,
          axisTick: {
            show: false, //去除刻度线
          },
          axisLine: {
            show: false, //去除轴线
          },
          axisLabel: {
            color: "#e8e8e8", //x轴文本颜色
            inside: true,
            formatter: function () {
              return "";
            },
          },
          splitLine: {
            // 分隔线
            show: true, // 默认显示，属性show控制显示与否
            lineStyle: {
              // 属性lineStyle（详见lineStyle）控制线条样式
              color: "#C2C2C2",
              width: 2,
              type: "solid",
            },
          },
        },
        yAxis: [
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: false, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
          },
        ],
        series: [
          {
            name: "全国",
            type: "line",
            data: this.qgLineData,
            smooth: true,
            yAxisIndex: 0,
            itemStyle: {
              normal: {
                color: "#D20000",
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
          {
            name: "班级",
            type: "line",
            data: this.ysLineData,
            smooth: true,
            yAxisIndex: 0,
            itemStyle: {
              normal: {
                color: "#00116A",
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
        ],
      });
    },
    // 条形图
    drawYdLine(id) {
      if (this.ydLineChart == null) {
        this.ydLineChart = echarts.init(document.getElementById(id));
      }
      this.ydLineChart.setOption({
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,0.7)", // 提示背景颜色，默认为透明度为0.7的黑色
          borderColor: "#333", // 提示边框颜色
          borderRadius: 4, // 提示边框圆角，单位px，默认为4
          borderWidth: 0, // 提示边框线宽，单位px，默认为0（无边框）
          formatter: function (datas) {
            var res = datas[0].axisValue + "<br/>";
            for (var i = 0; i < datas.length; i++) {
              var val = datas[i].value;
              res += datas[i].seriesName + "：" + val + "%<br/>";
            }
            return res;
          },
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
            lineStyle: {
              // 直线指示器样式设置
              color: "#48b",
              width: 2,
              type: "solid",
            },
            shadowStyle: {
              // 阴影指示器样式设置
              width: "auto", // 阴影大小
              color: "rgba(0, 255, 198, 0.2)", // 阴影颜色
            },
          },
          textStyle: {
            color: "#fff",
          },
        },
        grid: this.grid2,
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.xData2,
          axisTick: {
            show: false, //去除刻度线
          },
          axisLine: {
            show: false, //去除轴线
          },
          axisLabel: {
            color: "#e8e8e8", //x轴文本颜色
            inside: true,
            formatter: function () {
              return "";
            },
          },
          splitLine: {
            // 分隔线
            show: true, // 默认显示，属性show控制显示与否
            lineStyle: {
              // 属性lineStyle（详见lineStyle）控制线条样式
              color: "#C2C2C2",
              width: 2,
              type: "solid",
            },
          },
        },
        yAxis: [
          {
            type: "value",
            axisLine: {
              // 分隔线
              show: false, // 默认显示，属性show控制显示与否
              lineStyle: {
                // 属性lineStyle（详见lineStyle）控制线条样式
                color: "#C2C2C2",
                width: 4,
                type: "solid",
              },
            },
            axisLabel: {
              formatter: function () {
                return "";
              },
            },
          },
        ],
        series: [
          {
            name: "全国",
            type: "line",
            data: this.qgLineData,
            smooth: true,
            yAxisIndex: 0,
            itemStyle: {
              normal: {
                color: "#D20000",
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
          {
            name: "班级",
            type: "line",
            data: this.ysLineData,
            smooth: true,
            yAxisIndex: 0,
            itemStyle: {
              normal: {
                color: "#00116A",
                lineStyle: {
                  width: 6, //设置线条粗细
                },
              },
            },
          },
        ],
      });
    },
    // 仪表盘
    drawYbChart(id) {
      if (this.tzYbChart == null) {
        this.tzYbChart = echarts.init(document.getElementById(id));
      }
      this.tzYbChart.setOption({
        grid: {
          top: "0px",
          left: "0px",
          right: "0px",
          bottom: "0px",
        },
        series: [
          {
            type: "gauge",
            radius: "100%",
            startAngle: 180,
            endAngle: 0,
            min: 0,
            max: 100,
            splitNumber: 10,
            axisLine: {
              lineStyle: {
                width: 6,
                color: [
                  [0.6, "#148c00"],
                  [0.8, "#4e0080"],
                  [0.9, "#001166"],
                  [1, "#660000"],
                ],
              },
            },
            pointer: {
              icon: "path://M12.8,0.7l12,40.1H0.7L12.8,0.7z",
              length: "50%",
              width: 20,
              offsetCenter: [0, "0%"],
              itemStyle: {
                color: "auto",
              },
            },
            axisLabel: {
              show: false,
            },
            axisTick: {
              show: true,
              length: 12,
              lineStyle: {
                color: "auto",
                width: 2,
              },
            },
            splitLine: {
              length: 20,
              lineStyle: {
                color: "auto",
                width: 5,
              },
            },
            title: {
              show: true,
              offsetCenter: [0, "-20%"],
              fontSize: 30,
            },
            detail: {
              fontSize: 50,
              offsetCenter: [0, "0%"],
              valueAnimation: false,
              formatter: function (value) {
                return value < 60
                  ? "需努力"
                  : value < 80
                  ? "合格"
                  : value < 90
                  ? "良好"
                  : "优秀";
              },
              color: "auto",
            },
            data: this.ybData,
          },
        ],
      });
    },
    // 仪表盘
    drawYdYbChart(id) {
      if (this.ydYbChart == null) {
        this.ydYbChart = echarts.init(document.getElementById(id));
      }
      this.ydYbChart.setOption({
        grid: {
          top: "0px",
          left: "0px",
          right: "0px",
          bottom: "0px",
        },
        series: [
          {
            type: "gauge",
            radius: "100%",
            startAngle: 180,
            endAngle: 0,
            min: 0,
            max: 100,
            splitNumber: 10,
            axisLine: {
              lineStyle: {
                width: 6,
                color: [
                  [0.6, "#148c00"],
                  [0.8, "#4e0080"],
                  [0.9, "#001166"],
                  [1, "#660000"],
                ],
              },
            },
            pointer: {
              icon: "path://M12.8,0.7l12,40.1H0.7L12.8,0.7z",
              length: "50%",
              width: 20,
              offsetCenter: [0, "0%"],
              itemStyle: {
                color: "auto",
              },
            },
            axisLabel: {
              show: false,
            },
            axisTick: {
              show: true,
              length: 12,
              lineStyle: {
                color: "auto",
                width: 2,
              },
            },
            splitLine: {
              length: 20,
              lineStyle: {
                color: "auto",
                width: 5,
              },
            },
            title: {
              show: true,
              offsetCenter: [0, "-20%"],
              fontSize: 30,
            },
            detail: {
              fontSize: 50,
              offsetCenter: [0, "0%"],
              valueAnimation: false,
              formatter: function (value) {
                return value < 60
                  ? "需努力"
                  : value < 80
                  ? "合格"
                  : value < 90
                  ? "良好"
                  : "优秀";
              },
              color: "auto",
            },
            data: this.ybData,
          },
        ],
      });
    },
    shotPic(id) {
      const node = document.getElementById(id); // 通过id获取dom
      console.log(node, "node");
      domtoimage
        .toPng(node)
        .then((dataUrl) => {
          this.loading = false;
          //输出图片的Base64,dataUrl
          // 下载到PC
          const a = document.createElement("a"); // 生成一个a元素
          const event = new MouseEvent("click"); // 创建一个单击事件
          a.download = "班级综合报告"; // 设置图片名称没有设置则为默认
          a.href = dataUrl; // 将生成的URL设置为a.href属性
          a.dispatchEvent(event); // 触发a的单击事件
        })
        .catch(function (error) {
          this.loading = false;
          console.error("oops, something went wrong!", error);
        });
    },
    // 获取月份
    getMonth() {
      this.api.pes.ysReportGetMonth().then((res) => {
        this.monthList = res.data;
        this.monthType = this.monthList[0].value;
        this.monthValue = this.monthList[0].name;
        this.getTableData();
        this.getTzData();
        this.getBg();
      });
    },
    // 获取三维数据
    getTableData() {
      let data = {
        month: this.monthType,
        type: 0,
        schoolId: this.$store.state.userInfo.schoolId,
        gradeId: this.currentGradeId,
      };
      this.api.pes.ysReportMonthNum(data).then((res) => {
        this.num1 = res.data.z;
        this.num2 = res.data.x;
        this.num3 = res.data.y;
      });
    },
    // 获取运动数据
    getSportData() {
      let data = {
        month: this.monthType,
        schoolId: this.$store.state.userInfo.schoolId,
        gradeId: this.currentGradeId,
      };
      this.api.pes.ysReportMonthSportData(data).then((res) => {
        this.sportData = res.data;
        this.s_score1 = this.sportData.run.score;
        this.s_score2 = this.sportData.jump.score;
        this.s_score3 = this.sportData.agile.score;
        this.s_score4 = this.sportData.balance.score;
        this.s_score5 = this.sportData.coordinate.score;
        this.s_score6 = this.sportData.power.score;
        this.s_score7 = this.sportData.ctrlHand.score;
        this.s_score8 = this.sportData.ctrlFoot.score;
        this.s_width1 = this.formatWidth(this.s_score1);
        this.s_width2 = this.formatWidth(this.s_score2);
        this.s_width3 = this.formatWidth(this.s_score3);
        this.s_width4 = this.formatWidth(this.s_score4);
        this.s_width5 = this.formatWidth(this.s_score5);
        this.s_width6 = this.formatWidth(this.s_score6);
        this.s_width7 = this.formatWidth(this.s_score7);
        this.s_width8 = this.formatWidth(this.s_score8);
        this.s_ysHeight1 = this.formatHeight(this.s_score1);
        this.s_qgHeight1 = this.formatHeight(this.sportData.run.countryScore);
        this.s_ysHeight2 = this.formatHeight(this.s_score2);
        this.s_qgHeight2 = this.formatHeight(this.sportData.jump.countryScore);
        this.s_ysHeight3 = this.formatHeight(this.s_score3);
        this.s_qgHeight3 = this.formatHeight(this.sportData.agile.countryScore);
        this.s_ysHeight4 = this.formatHeight(this.s_score4);
        this.s_qgHeight4 = this.formatHeight(
          this.sportData.balance.countryScore
        );
        this.s_ysHeight5 = this.formatHeight(this.s_score5);
        this.s_qgHeight5 = this.formatHeight(
          this.sportData.coordinate.countryScore
        );
        this.s_ysHeight6 = this.formatHeight(this.s_score6);
        this.s_qgHeight6 = this.formatHeight(this.sportData.power.countryScore);
        this.s_ysHeight7 = this.formatHeight(this.s_score7);
        this.s_qgHeight7 = this.formatHeight(
          this.sportData.ctrlHand.countryScore
        );
        this.s_ysHeight8 = this.formatHeight(this.s_score8);
        this.s_qgHeight8 = this.formatHeight(
          this.sportData.ctrlFoot.countryScore
        );
      });
    },
    // 获取体质数据
    getTzData() {
      let data = {
        month: this.monthType,
        schoolId: this.$store.state.userInfo.schoolId,
        gradeId: this.currentGradeId,
      };
      this.api.pes.ysReportMonthTzData(data).then((res) => {
        this.tzData = res.data;
        this.score1 = this.tzData.height.score;
        this.score2 = this.tzData.weight.score;
        this.score3 = this.tzData.eyeL.score;
        this.score4 = this.tzData.arm.score;
        this.score5 = this.tzData.lung.score;
        this.score6 = this.tzData.bust.score;
        this.score7 = this.tzData.flexion.score;
        this.width1 = this.formatWidth(this.score1);
        this.width2 = this.formatWidth(this.score2);
        this.width3 = this.formatWidth(this.score3);
        this.width4 = this.formatWidth(this.score4);
        this.width5 = this.formatWidth(this.score5);
        this.width6 = this.formatWidth(this.score6);
        this.width7 = this.formatWidth(this.score7);
        this.ysHeight1 = this.formatHeight(this.score1);
        this.qgHeight1 = this.formatHeight(this.tzData.height.countryScore);
        this.ysHeight2 = this.formatHeight(this.score2);
        this.qgHeight2 = this.formatHeight(this.tzData.weight.countryScore);
        this.ysHeight3 = this.formatHeight(this.score3);
        this.qgHeight3 = this.formatHeight(this.tzData.eyeL.countryScore);
        this.ysHeight4 = this.formatHeight(this.score4);
        this.qgHeight4 = this.formatHeight(this.tzData.arm.countryScore);
        this.ysHeight5 = this.formatHeight(this.score5);
        this.qgHeight5 = this.formatHeight(this.tzData.lung.countryScore);
        this.ysHeight6 = this.formatHeight(this.score6);
        this.qgHeight6 = this.formatHeight(this.tzData.bust.countryScore);
        this.ysHeight7 = this.formatHeight(this.score7);
        this.qgHeight7 = this.formatHeight(this.tzData.flexion.countryScore);
      });
    },
    // 获取综合报告
    getBg() {
      let data = {
        month: this.monthType,
        schoolId: this.$store.state.userInfo.schoolId,
        type: this.operateType == 1 ? 1 : 0,
        gradeId: this.currentGradeId,
      };
      this.api.pes.ysReportMonthComReport(data).then((res) => {
        this.chartValue = res.data;
        if (this.operateType == 1) {
          let tzV = this.chartValue.founda;
          this.preValue = [];
          this.preValue.push(tzV.height.value);
          this.preValue.push(tzV.weight.value);
          this.preValue.push(tzV.eye.value);
          this.preValue.push(tzV.arm.value);
          this.preValue.push(tzV.lung.value);
          this.preValue.push(tzV.bust.value);
          this.preValue.push(tzV.flexion.value);
          this.nextValue = [];
          this.nextValue.push(tzV.height.lastValue);
          this.nextValue.push(tzV.weight.lastValue);
          this.nextValue.push(tzV.eye.lastValue);
          this.nextValue.push(tzV.arm.lastValue);
          this.nextValue.push(tzV.lung.lastValue);
          this.nextValue.push(tzV.bust.lastValue);
          this.nextValue.push(tzV.flexion.lastValue);

          this.qgLineData = [];
          this.qgLineData.push(tzV.height.countryRiseRate);
          this.qgLineData.push(tzV.weight.countryRiseRate);
          this.qgLineData.push(tzV.eye.countryRiseRate);
          this.qgLineData.push(tzV.arm.countryRiseRate);
          this.qgLineData.push(tzV.lung.countryRiseRate);
          this.qgLineData.push(tzV.bust.countryRiseRate);
          this.qgLineData.push(tzV.flexion.countryRiseRate);
          this.ysLineData = [];
          this.ysLineData.push(tzV.height.riseRate);
          this.ysLineData.push(tzV.weight.riseRate);
          this.ysLineData.push(tzV.eye.riseRate);
          this.ysLineData.push(tzV.arm.riseRate);
          this.ysLineData.push(tzV.lung.riseRate);
          this.ysLineData.push(tzV.bust.riseRate);
          this.ysLineData.push(tzV.flexion.riseRate);
          this.ybData = [
            {
              value: res.data.score,
              title:
                res.data.score < 60
                  ? "需努力"
                  : res.data.score < 80
                  ? "合格"
                  : res.data.score < 90
                  ? "良好"
                  : "优秀",
            },
          ];
          this.$nextTick(function () {
            this.drawBar("tzBarChart");
            this.drawLine("tzLineChart");
            this.drawYbChart("tzYbChart");
          });
          this.fxysValue1 = tzV.height.value;
          this.fxqgValue1 = tzV.height.countryValue;
          this.fxysValue2 = tzV.weight.value;
          this.fxqgValue2 = tzV.weight.countryValue;
          this.fxysValue3 = tzV.eye.value;
          this.fxqgValue3 = tzV.eye.countryValue;
          this.fxysValue4 = tzV.arm.value;
          this.fxqgValue4 = tzV.arm.countryValue;
          this.fxysValue5 = tzV.lung.value;
          this.fxqgValue5 = tzV.lung.countryValue;
          this.fxysValue6 = tzV.bust.value;
          this.fxqgValue6 = tzV.bust.countryValue;
          this.fxysValue7 = tzV.flexion.value;
          this.fxqgValue7 = tzV.flexion.countryValue;
          this.fxysHeight1 = this.formatFxHeight(tzV.height.score);
          this.fxqgHeight1 = this.formatFxHeight(tzV.height.countryScore);
          this.fxysHeight2 = this.formatFxHeight(tzV.weight.score);
          this.fxqgHeight2 = this.formatFxHeight(tzV.weight.countryScore);
          this.fxysHeight3 = this.formatFxHeight(tzV.eye.score);
          this.fxqgHeight3 = this.formatFxHeight(tzV.eye.countryScore);
          this.fxysHeight4 = this.formatFxHeight(tzV.arm.score);
          this.fxqgHeight4 = this.formatFxHeight(tzV.arm.countryScore);
          this.fxysHeight5 = this.formatFxHeight(tzV.lung.score);
          this.fxqgHeight5 = this.formatFxHeight(tzV.lung.countryScore);
          this.fxysHeight6 = this.formatFxHeight(tzV.bust.score);
          this.fxqgHeight6 = this.formatFxHeight(tzV.bust.countryScore);
          this.fxysHeight7 = this.formatFxHeight(tzV.flexion.score);
          this.fxqgHeight7 = this.formatFxHeight(tzV.flexion.countryScore);
        } else {
          let tzV = this.chartValue.sport;
          this.preValue = [];
          this.preValue.push(tzV.run.value);
          this.preValue.push(tzV.jump.value);
          this.preValue.push(tzV.agile.value);
          this.preValue.push(tzV.balance.value);
          this.preValue.push(tzV.coordinate.value);
          this.preValue.push(tzV.power.value);
          this.preValue.push(tzV.ctrlHand.value);
          this.preValue.push(tzV.ctrlFoot.value);
          this.nextValue = [];
          this.nextValue.push(tzV.run.lastValue);
          this.nextValue.push(tzV.jump.lastValue);
          this.nextValue.push(tzV.agile.lastValue);
          this.nextValue.push(tzV.balance.lastValue);
          this.nextValue.push(tzV.coordinate.lastValue);
          this.nextValue.push(tzV.power.lastValue);
          this.nextValue.push(tzV.ctrlHand.lastValue);
          this.nextValue.push(tzV.ctrlFoot.lastValue);

          this.qgLineData = [];
          this.qgLineData.push(tzV.run.countryRiseRate);
          this.qgLineData.push(tzV.jump.countryRiseRate);
          this.qgLineData.push(tzV.agile.countryRiseRate);
          this.qgLineData.push(tzV.balance.countryRiseRate);
          this.qgLineData.push(tzV.coordinate.countryRiseRate);
          this.qgLineData.push(tzV.power.countryRiseRate);
          this.qgLineData.push(tzV.ctrlHand.countryRiseRate);
          this.qgLineData.push(tzV.ctrlFoot.countryRiseRate);
          this.ysLineData = [];
          this.ysLineData.push(tzV.run.riseRate);
          this.ysLineData.push(tzV.jump.riseRate);
          this.ysLineData.push(tzV.agile.riseRate);
          this.ysLineData.push(tzV.balance.riseRate);
          this.ysLineData.push(tzV.coordinate.riseRate);
          this.ysLineData.push(tzV.power.riseRate);
          this.ysLineData.push(tzV.ctrlHand.riseRate);
          this.ysLineData.push(tzV.ctrlFoot.riseRate);
          this.ybData = [
            {
              value: res.data.score,
              title:
                res.data.score < 60
                  ? "需努力"
                  : res.data.score < 80
                  ? "合格"
                  : res.data.score < 90
                  ? "良好"
                  : "优秀",
            },
          ];
          this.$nextTick(function () {
            this.drawYdBar("ydBarChart");
            this.drawYdLine("ydLineChart");
            this.drawYdYbChart("ydYbChart");
          });
          this.s_fxysValue1 = tzV.run.value;
          this.s_fxqgValue1 = tzV.run.countryValue;
          this.s_fxysValue2 = tzV.jump.value;
          this.s_fxqgValue2 = tzV.jump.countryValue;
          this.s_fxysValue3 = tzV.agile.value;
          this.s_fxqgValue3 = tzV.agile.countryValue;
          this.s_fxysValue4 = tzV.balance.value;
          this.s_fxqgValue4 = tzV.balance.countryValue;
          this.s_fxysValue5 = tzV.coordinate.value;
          this.s_fxqgValue5 = tzV.coordinate.countryValue;
          this.s_fxysValue6 = tzV.power.value;
          this.s_fxqgValue6 = tzV.power.countryValue;
          this.s_fxysValue7 = tzV.ctrlHand.value;
          this.s_fxqgValue7 = tzV.ctrlHand.countryValue;
          this.s_fxysValue8 = tzV.ctrlFoot.value;
          this.s_fxqgValue8 = tzV.ctrlFoot.countryValue;
          this.s_fxysHeight1 = this.formatFxHeight(tzV.run.score);
          this.s_fxqgHeight1 = this.formatFxHeight(tzV.run.countryScore);
          this.s_fxysHeight2 = this.formatFxHeight(tzV.jump.score);
          this.s_fxqgHeight2 = this.formatFxHeight(tzV.jump.countryScore);
          this.s_fxysHeight3 = this.formatFxHeight(tzV.agile.score);
          this.s_fxqgHeight3 = this.formatFxHeight(tzV.agile.countryScore);
          this.s_fxysHeight4 = this.formatFxHeight(tzV.balance.score);
          this.s_fxqgHeight4 = this.formatFxHeight(tzV.balance.countryScore);
          this.s_fxysHeight5 = this.formatFxHeight(tzV.coordinate.score);
          this.s_fxqgHeight5 = this.formatFxHeight(tzV.coordinate.countryScore);
          this.s_fxysHeight6 = this.formatFxHeight(tzV.power.score);
          this.s_fxqgHeight6 = this.formatFxHeight(tzV.power.countryScore);
          this.s_fxysHeight7 = this.formatFxHeight(tzV.ctrlHand.score);
          this.s_fxqgHeight7 = this.formatFxHeight(tzV.ctrlHand.countryScore);
          this.s_fxysHeight8 = this.formatFxHeight(tzV.ctrlFoot.score);
          this.s_fxqgHeight8 = this.formatFxHeight(tzV.ctrlFoot.countryScore);
        }
      });
    },
    getSchoolGradeList() {
      let that = this;
      this.api.school
        .getSchoolGradeList(this.$store.state.userInfo.schoolId)
        .then((res) => {
          console.log(res);
          if (res.flag) {
            that.classList.push(...res.data.小班);
            that.classList.push(...res.data.中班);
            that.classList.push(...res.data.大班);
            console.log(that.classList);
            if (that.classList) {
              that.currentGradeId = that.classList[0].id;
              that.gradeName = that.classList[0].name;
              that.getMonth();
            }
          }
        });
    },
  },
  mounted() {
    this.flag = this.$route.query.flag;
    if (this.flag == 1) {
      this.currentGradeId = this.$route.query.gradeId;
      this.getMonth();
    } else {
      this.flag = 0;
      this.getSchoolGradeList();
    }
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
.column {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #e7e7e7;
  align-items: center;
  justify-content: center;
}
.row {
  display: flex;
  flex-direction: row;
}
.center {
  align-items: center;
  justify-content: center;
}
.mt50 {
  margin-top: 50px;
}
.column_base {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}
.title {
  font-size: 40px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #001166;
}
.image_c {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 760px;
  margin-left: -56px;
  .title1 {
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #001166;
  }
}
.image_l {
  position: relative;
  width: 760px;
  height: 640px;
  .image {
    width: 760px;
    height: 640px;
  }
  .num1 {
    position: absolute;
    top: 38px;
    right: 304px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
    width: 65px;
    text-align: center;
  }
  .num2 {
    position: absolute;
    bottom: 88px;
    left: 199px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
    width: 65px;
    text-align: center;
  }
  .num3 {
    position: absolute;
    bottom: 139px;
    right: 188px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
    width: 65px;
    text-align: center;
  }
  .num4 {
    position: absolute;
    top: 96px;
    right: 305px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
    width: 65px;
    text-align: center;
  }
  .num5 {
    position: absolute;
    bottom: 72px;
    left: 162px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
    width: 65px;
    text-align: center;
  }
  .num6 {
    position: absolute;
    bottom: 110px;
    right: 143px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
    width: 65px;
    text-align: center;
  }
  .num7 {
    position: absolute;
    top: 127px;
    right: 305px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
    width: 65px;
    text-align: center;
  }
  .num8 {
    position: absolute;
    bottom: 58px;
    left: 140px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
    width: 65px;
    text-align: center;
  }
  .num9 {
    position: absolute;
    bottom: 131px;
    right: 185px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
    width: 65px;
    text-align: center;
  }
  .num10 {
    position: absolute;
    top: 66px;
    right: 305px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
    width: 65px;
    text-align: center;
  }
  .num11 {
    position: absolute;
    bottom: 40px;
    left: 114px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
    width: 65px;
    text-align: center;
  }
  .num12 {
    position: absolute;
    bottom: 101px;
    right: 130px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
    width: 65px;
    text-align: center;
  }
  .num13 {
    position: absolute;
    top: 41px;
    right: 305px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
    width: 65px;
    text-align: center;
  }
  .num14 {
    position: absolute;
    bottom: 107px;
    left: 232px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
    width: 65px;
    text-align: center;
  }
  .num15 {
    position: absolute;
    bottom: 176px;
    right: 264px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
    width: 65px;
    text-align: center;
  }
  .num16 {
    position: absolute;
    top: 180px;
    right: 305px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
    width: 65px;
    text-align: center;
  }
  .num17 {
    position: absolute;
    bottom: 102px;
    left: 219px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
    width: 65px;
    text-align: center;
  }
  .num18 {
    position: absolute;
    bottom: 158px;
    right: 230px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #1c1642;
    width: 65px;
    text-align: center;
  }
}
.mt125 {
  margin-top: 125px;
}
.month_select1 {
  width: 124px;
  height: 48px;
  background: #ffffff;
  border: 3px solid #feba01;
  border-radius: 27px;
  cursor: pointer;
}
.month_normal1 {
  width: 124px;
  height: 48px;
  background: #e8e8e8;
  border: 3px solid #e8e8e8;
  border-radius: 27px;
  cursor: pointer;
}
.month_select2 {
  width: 124px;
  height: 48px;
  background: #ffffff;
  border: 3px solid #6c0000;
  border-radius: 27px;
  cursor: pointer;
}
.month_select3 {
  width: 124px;
  height: 48px;
  background: #ffffff;
  border: 3px solid #169f00;
  border-radius: 27px;
  cursor: pointer;
}
.month_select4 {
  width: 124px;
  height: 48px;
  background: #ffffff;
  border: 3px solid #bd0097;
  border-radius: 27px;
  cursor: pointer;
}
.month_select5 {
  width: 124px;
  height: 48px;
  background: #ffffff;
  border: 3px solid #fe0101;
  border-radius: 27px;
  cursor: pointer;
}
.month_select6 {
  width: 124px;
  height: 48px;
  background: #ffffff;
  border: 3px solid #e9fe01;
  border-radius: 27px;
  cursor: pointer;
}
.mrl30 {
  margin-left: 30px;
}
.mt25 {
  margin-top: 25px;
}
.circle1 {
  width: 35px;
  height: 35px;
  background: #feba01;
  border-radius: 50%;
}
.circle2 {
  width: 35px;
  height: 35px;
  background: #6c0000;
  border-radius: 50%;
}
.circle3 {
  width: 35px;
  height: 35px;
  background: #169f00;
  border-radius: 50%;
}
.circle4 {
  width: 35px;
  height: 35px;
  background: #bd0097;
  border-radius: 50%;
}
.circle5 {
  width: 35px;
  height: 35px;
  background: #fe0101;
  border-radius: 50%;
}
.circle6 {
  width: 35px;
  height: 35px;
  background: #e9fe01;
  border-radius: 50%;
}
.small_circle {
  width: 22px;
  height: 22px;
}
.width624 {
  width: 624px;
  max-width: 624px;
}
.top_text {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #001166;
  margin-left: 9px;
}
.white_bg {
  width: 100%;
  height: 102px;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .title {
    font-size: 50px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #3f3f3f;
  }
}
.row_center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.tz_select {
  width: 94px;
  height: 42px;
  background: #001689;
  border: 3px solid #07155e;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  font-size: 34px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 21px;
}
.tz_normal {
  width: 94px;
  height: 42px;
  background: #e8e8e8;
  border: 3px solid #d8d8d8;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  font-size: 34px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #3f3f3f;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 21px;
}
.yd_select {
  width: 94px;
  height: 42px;
  background: #001689;
  border: 3px solid #07155e;
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
  font-size: 34px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.yd_normal {
  width: 94px;
  height: 42px;
  background: #e8e8e8;
  border: 3px solid #d8d8d8;
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
  font-size: 34px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #3f3f3f;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.downLoad {
  width: 166px;
  height: 44px;
  background: #ffffff;
  border: 6px solid #00bdb6;
  font-size: 40px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #00bdb6;
  border-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 540px;
}
.width1242 {
  width: 1242px;
  display: flex;
  flex-direction: column;
}
.pes {
  width: 150px;
  height: 150px;
}
.mt36 {
  margin-top: 36px;
}
.mrl22 {
  margin-left: 22px;
}
.school_name {
  font-size: 38px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #001166;
}
.mt9 {
  margin-top: 9px;
}
.all_num {
  font-size: 38px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.site {
  font-size: 28px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  margin-left: 6px;
}
.address {
  width: 22px;
  height: 32px;
}
.flex {
  flex: 1;
}
.column_end {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.gjc {
  font-size: 34px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #172570;
}
.gjc_image {
  width: 185px;
  height: 103px;
  margin-top: 17px;
}
.gjc_image1 {
  width: 470px;
  height: 103px;
  margin-top: 17px;
}
.top_lan {
  display: flex;
  flex-direction: row;
  width: 1242px;
  height: 86px;
  background: #f1f1f1;
  align-items: center;
  margin-top: 15px;
  .title1 {
    font-size: 26px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    margin-left: 25px;
  }
  .title2 {
    font-size: 26px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    width: 208px;
  }
  .title3 {
    font-size: 26px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    margin-left: 27px;
  }
  .title4 {
    font-size: 26px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #9f9f9f;
    margin-left: 25px;
  }
}
.line {
  width: 1242px;
  height: 2px;
  background: #c8c8c8;
}
.image1 {
  width: 140px;
  height: 180px;
  margin-right: 6px;
}
.flag_text {
  font-size: 38px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #172570;
}
.mt12 {
  margin-top: 12px;
}
.avg_text {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #172570;
}
.avg_pf {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #8b8b8b;
  margin-top: 5px;
}
.width213 {
  width: 213px;
}
.width263 {
  width: 263px;
}
.progress {
  width: 587px;
  height: 8px;
  background-color: #e8e8e8;
}
.progressColor {
  height: 4px;
  background: #47d800;
  border: 2px solid #2d8a00;
  border-radius: 1px;
}
.progress_line {
  width: 586px;
  height: 32px;
}
.width586 {
  width: 586px;
}
.mt4 {
  margin-top: 4px;
}
.fs {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #172570;
  margin-left: 16px;
  margin-top: 15px;
}
.progress_arrow {
  width: 21px;
  height: 32px;
}
.line_v {
  width: 2px;
  height: 154px;
  background: #c8c8c8;
}
.mt17 {
  margin-top: 17px;
}
.mrl5 {
  margin-left: 5px;
}
.mt27 {
  margin-top: 27px;
}
.form-control {
  height: 50px;
  border: solid 1px #d9d9d9;
  box-sizing: border-box;
  padding: 0rem 0.25rem;
  border-radius: 0.25rem;
}
.text1 {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #8b8b8b;
}
.mt13 {
  margin-top: 13px;
}
.mt20 {
  margin-top: 18px;
}
.max {
  width: 93%;
}
.arrow_v {
  width: 10px;
  height: 22px;
}
.mrl10 {
  margin-left: 10px;
}
.pos {
  position: relative;
}
.ys_pos {
  display: flex;
  flex-direction: column;
  height: 134px;
  width: 14px;
  .bg {
    width: 14px;
    background: #1b1ec0;
  }
  .pro {
    width: 14px;
    background: #d7d7d7;
  }
  .qg_bg {
    width: 14px;
    background: #c0281b;
  }
}
.ys_text {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #1b1ec0;
  margin-top: 9px;
}
.qg_text {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #c0281b;
  margin-top: 9px;
}
.mrl42 {
  margin-left: 32px;
}
.column_center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mrl13 {
  margin-left: 13px;
}
.status1 {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fe0101;
}
.status2 {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #8b8b8b;
}
.mt19 {
  margin-top: 19px;
}
.mt137 {
  margin-top: 137px;
}
.mt33 {
  margin-top: 33px;
}
.widthMax {
  width: 100%;
}
.pre {
  width: 14px;
  height: 91px;
  background: #bc5050;
  border: 2px solid #960e0e;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.next {
  width: 14px;
  height: 91px;
  background: #001166;
  border: 2px solid #0022cc;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bg_row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.barChart {
  height: 300px;
  width: 400px;
  margin-top: 25px;
}
.mt5 {
  margin-top: 5px;
}
.fx {
  font-size: 36px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #172570;
}
.color1 {
  width: 32px;
  height: 32px;
  background: #960e0e;
  min-width: 32px;
}
.color2 {
  width: 32px;
  height: 32px;
  background: #009c80;
  min-width: 32px;
}
.color3 {
  width: 32px;
  height: 32px;
  background: #f7d046;
  min-width: 32px;
}
.color4 {
  width: 32px;
  height: 32px;
  background: #001166;
  min-width: 32px;
}
.value {
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #172570;
  margin-left: 17px;
}
.circle_left {
  border-radius: 0px 0 0px 25px;
  height: 17px;
  width: 17px;
  background-color: #e8e8e8;
  border-bottom: 6px solid #1a1ec8;
  border-left: 6px solid #1a1ec8;
}
.left_pos {
  position: relative;
  width: 6px;
  height: 180px;
}
.left_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 6px;
  height: 180px;
  background: #d7d7d7;
}
.abs {
  position: absolute;
  top: 0;
  left: 0;
}
.mrl49 {
  margin-left: 49px;
}
.circle_right {
  border-radius: 0px 0 25px 0;
  height: 17px;
  width: 17px;
  background-color: #e8e8e8;
  border-bottom: 6px solid #d20000;
  border-right: 6px solid #d20000;
}
.right_bg {
  width: 6px;
  height: 180px;
  background: #d7d7d7;
  margin-left: 34px;
}
.mrl34 {
  margin-left: 34px;
}
.mrl20 {
  margin-left: 20px;
}
.left_value {
  width: 41px;
  height: 14px;
  background: #ffffff;
  border: 2px solid #1b1ec0;
  border-radius: 9px;
  font-size: 14px;
  font-family: AlibabaPuHuiTi_2_85_Bold;
  font-weight: 400;
  color: #001166;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -20px;
}
.right_value {
  width: 41px;
  height: 14px;
  background: #ffffff;
  border: 2px solid #c0281b;
  border-radius: 9px;
  font-size: 14px;
  font-family: AlibabaPuHuiTi_2_85_Bold;
  font-weight: 400;
  color: #660000;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -20px;
}
.left_y {
  width: 6px;
  height: 6px;
  background: #1b1ec0;
  border-radius: 50%;
}
.left_v {
  width: 6px;
  background: #1b1ec0;
}
.right_y {
  width: 6px;
  height: 6px;
  background: #c0281b;
  border-radius: 50%;
}
.right_v {
  width: 6px;
  background: #c0281b;
}
.bot_text {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #001166;
  margin-top: 6px;
}
.h180 {
  height: 180px;
}
.tzLineChart {
  width: 504px;
  height: 215px;
}
.width504 {
  width: 504px;
}
.abc1 {
  width: 14px;
  height: 56px;
  background: #148c00;
  border: 2px solid #1cc600;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.abc2 {
  width: 14px;
  height: 54px;
  background: #4e0080;
  border: 2px solid #7d00cc;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.abc3 {
  width: 14px;
  height: 54px;
  background: #001166;
  border: 2px solid #0022cc;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.abc4 {
  width: 14px;
  height: 56px;
  background: #660000;
  border: 2px solid #c0281b;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tzYbChart {
  width: 555px;
  height: 446px;
  margin-top: 200px;
}
.yb_text {
  width: 222px;
  height: 56px;
  background: #ffffff;
  border: 7px solid #c8c8c8;
  border-radius: 28px;
  font-size: 34px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #172570;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 30%;
}
.h300 {
  height: 300px;
}
.h240 {
  height: 120px;
}
.el-input--suffix .el-input__inner {
  padding-left: 0 !important;
  font-size: 0.18rem;
  font-family: Alibaba PuHuiTi !important;
  color: #000;
}
/deep/.el-input__inner {
  border: none;
  background-color: #e8e8e8;
  width: 100px;
}
.el-cascader .el-input .el-input__inner:focus,
.el-cascader .el-input.is-focus .el-input__inner {
  border: none;
  padding-left: 0 !important;
  font-family: Alibaba PuHuiTi !important;
  color: #000;
}
</style>